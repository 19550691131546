<template>
  <div>
    <div class="container">
        <div class="success-img-holder text-center mt-5 mb-3">
          <figure>
            <img src="~@/assets/images/payment_scuccessful.png" alt="" />
          </figure>
          <figcaption class="mb-4">
            <!-- <h1>Payment Successful!</h1> -->
            <h1>Thank You</h1><br>
            <div v-if="this.proposalStatus">
            <span >{{this.proposalStatus}}.</span><br><br>
            </div>
            <div v-else>
            <span>An email has been successfully triggered to the customer.</span><br><br>
            <span>Customer has to review and submit the proposal along with making a payment.</span>
            </div>
          </figcaption>
        </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "paymnt",
  props: {  },
  components: {},
  data() {
    return {
      proposalStatus:"",
    };
  },
  mounted() {
    // this.paymentResponse();
    //this.getPayment()
    let s=this.$route.query.msg
    this.proposalStatus=s
  
  },
  methods: {
    
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/paymentsuccess.scss";
// @import "~@/assets/scss/components/paymentfailed.scss";
</style>
<template>
  <div>
    <div class="container">
        <div class="failed-img-holder text-center mt-5 mb-3">
          <figure>
            <img src="~@/assets/images/payment_failed.png" alt="" />
          </figure>
          <br>
          <figcaption class="mb-4">
            <!-- <h1>Payment Successful!</h1> -->
            <!-- <div v-if="this.status=='Error'">
                <h1  id="statusError">{{this.status}}</h1>
            </div> -->
            <!-- <div v-if="this.status=='Success'">
                <h1  id="statusSuccess">{{this.status}}</h1><br>
            </div>
            <div v-if="this.status=='Warning'">
                 <h1  id="statusWarning">{{this.status}}</h1><br>
            </div> -->
            <span id="errorText">Sorry, something went wrong, Please try again after sometime.</span><br>
            <!-- <span id="errorText">We are facing with following error.</span><br> -->
            <!-- <span id="errorText">It seems like we are encountering some issues at our side</span><br> -->
            <div v-if="this.msg.length=='1'">
            <span  id="errorMsg">{{this.msg[0]}}.</span>
            </div>

            <div v-else>
             <span v-if="!flag" id="errorMsg">{{this.msg[0]}}....<button id="moreBtn" v-on:click="moreError">more</button></span>
            <span v-if="flag" id="errorMsg">{{this.msg[0]}}....<button id="moreBtn" v-on:click="moreError">less</button>
                </span>
            </div>
            <!-- <div v-if="flag"> -->
            <div v-if="flag" v-for="(error,index) in this.msg" :key="error">
            <span v-if="index!='0'" id="errorMsg">{{error}}.</span>
            </div>
            <!-- </div>  -->
           
            
          </figcaption>
        </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "alertdiypage",
  props: {  },
  components: {},
  data() {
    return {
        status:"",
        msg:[],
        listError:[],
        errorCode:"",
        backUrl:"",
        flag:false,
    };
  },
  created(){
    let m=this.$route.query.msg
    let back=this.$route.query.backUrl
    
    this.backUrl=back
    //  this.status=s
     this.msg=m
     for(let i=0;i<this.msg.length;i++)
     {
        console.log("i",this.msg[i])
     }
     
    //  console.log("abcd",this.$route.query.msg,this.status,this.msg)
  },
  methods:{
        moreError(){
        this.flag=!this.flag
    },
  },
  mounted() {
    
   
   
    
    // this.paymentResponse();
    //this.getPayment()
  
  },
  methods: {
    moreError(){
        this.flag=!this.flag
    },
  },
};
</script>

<style scoped lang="scss">
#statusError{
    color:rgb(245, 94, 94)
}
#statusSuccess{
    color:rgb(83, 206, 83)
}
#statusWarning{
    color:rgb(243, 185, 78)
}
#errorText{
    font-size: 20px;
}
#errorMsg{
    font-size: 15px;
    font-weight: 400;
    text-align: left;
}
#moreBtn{
    color: blue;
    cursor: pointer;
    border: none;
    background-color: white;
}
#btn{
    width:70px;
    height:30px;

    font-size: 15px;
    border:solid 1px rgb(245, 94, 94);
    border-radius: 4px;
    background-color: rgb(245, 94, 94)
}
@import "~@/assets/scss/components/paymentsuccess.scss";
// @import "~@/assets/scss/components/paymentfailed.scss";
</style>
<template>
  <div class="loading_block_outer" v-if="loading">
    <Loading2 />
  </div>
<div v-else>
  <div class="inner-page-bg">
    <div class="checkout-container">
      <div>
        <router-link to="/proposal" class="back-to-link">
          <img src="~@/assets/images/back-arrow.png" alt="" />
          Back to Proposal
        </router-link>
      </div>
      <!-- <div class="text-light bg-danger mb-3" v-if="spDetailsError">SP details not available, please login first!</div> -->
      

      <div class="policyname" v-if="selectedPlan">
        <div class="policyname-text d-flex align-items-center">
          <router-link to="/proposal" class="back-arrow">
            <img src="~@/assets/images/left-arrow-grey.png" alt="">
          </router-link>
          <span class="mr-4">
           <img
              :src="
                getInsurerImgs(
                  selectedPlan['insuranceAndProducts']['insuranceCompanyCode']
                )
              "
              alt=""
            />
          </span>
          <h6>{{ selectedPlan['insuranceAndProducts']['productName'] }}</h6>
        </div>
      </div>
      <div class="chckout-table rounded position-relative">
        <h5>Please review below details before proceeding ahead.</h5>
        <span class="subtext d-block text-center">These cannot be changed at a later stage</span>
        <div class="accordion" id="checkoutaccordion">
          <div class="card z-depth-0" v-for="(value, ind) in formInfo.fieldGroups" :key="ind">
            <div class="" v-if="ind != 'traceInfo' && ind != 'distributor'">
              <div class="card-header" :id="'headingOne_' + ind" v-if="!value.visibility?true:checkVisibility(value.visibility)">
                <button class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#collapseOne_' + ind" aria-expanded="true" :aria-controls="'collapseOne_' + ind">
                  <!-- <h6>{{ convertSentenceCase(value["name"]) }}</h6> -->
                  <h6>{{ convertSentenceCase(value["label"]) }}</h6>
                </button>
                <div class="d-flex cursor-pointer" @click="editDetails(ind)">
                  <img src="~@/assets/images/edit-profile.png">
                  Edit
                </div>
              </div>
              
              <div :id="'collapseOne_' + ind" :class="'collapse ' + getShow(ind)" :aria-labelledby="'headingOne_' + ind" data-parent="#checkoutaccordion" v-if="!value.visibility?true:checkVisibility(value.visibility)">
                <div class="card-body">
                  <div class="without-fieldset">
                    <!-- If Fields -->
                    <div class="pro-details">
                      <template class="" v-for="(value2, ind2) in value.fields" :key="ind2"> 
                        <div class="mb-4" v-if="value2.type == 'static'">
                          <div>
                            <h6>{{ value2["label"] }}</h6>
                          </div>
                        </div>
                        <div class="mb-4" v-if="value2.input != ''">
                          <div v-if="value2.type == 'single-select'">
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{ getSelectValue(value2.value, value2["input"]) }}</b>
                          </div>
                          <div v-else-if="value2.type == 'multi-select'">
                            <h6>{{ value2["label"] }}</h6>
                            <!-- <b>{{ getMultiSelectValue(value2.value, value2["input"]) }}</b> -->
                            <b>{{ value2["input"].toString() }}</b>
                          </div>
                          <div v-else-if="value2.type == 'boolean'">
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{ (value2["input"] == '1')?'Yes':'No' }}</b>
                          </div>
                          <div v-else-if="value2.type == 'date'">
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{ dateconvert(value2["input"]) }}</b>
                          </div>
                          <div v-else-if="value2.type == 'file'">
                            <h6>{{ value2["label"] }}</h6>
                            <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fields[ind2].input" @click="previewFiles(formInfo.fieldGroups[ind].fields[ind2].input)">
                              <img src="~@/assets/images/eye.png" alt="" />
                            </button>
                          </div>
                          <div v-else-if="value2.type == 'checkbox'">
                            <h6>
                              <img v-if="value2.input" src="~@/assets/images/check-mark.svg" class="check-markimg" alt=""/> 
                              {{ value2["label"] }}
                            </h6>
                            <!-- <b>{{ value2["input"] }}</b> -->
                          </div>
                          <div v-else>
                            <h6>{{ value2["label"] }}</h6>
                            <b>{{  value2["input"] }}</b>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <template v-for="(value2, ind2) in value.fieldGroups" :key="ind2">
                    <fieldset v-if="!value2.visibility?true:checkVisibility(value2.visibility)">
                      <!-- <legend>{{ convertSentenceCase(value2["name"]) }}</legend> -->
                      <legend>{{ convertSentenceCase(value2["label"]) }}</legend>
                      <div class="pro-details">
                        <template class="" v-for="(value3, ind3) in value2.fields" :key="ind3">
                          <div class="mb-4" v-if="value3.type == 'static'">
                            <div>
                              <h6>{{ value3["label"] }}</h6>
                            </div>
                          </div>
                          
                          <div class="mb-4" v-if="value3.input != ''">
                            <div v-if="value3.type == 'single-select'">
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ getSelectValue(value3.value, value3["input"]) }}</b>
                            </div>
                            <div v-else-if="value3.type == 'multi-select'">
                              <h6>{{ value3["label"] }}</h6>
                              <!-- <b>{{ getMultiSelectValue(value3.value, value3["input"]) }}</b> -->
                              <b>{{ value3["input"].toString() }}</b>
                            </div>
                            <div v-else-if="value3.type == 'boolean'">
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ (value3["input"] == '1')?'Yes':'No' }}</b>
                            </div>
                            <div v-else-if="value3.type == 'date' && value3.input">
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ dateconvert(value3["input"]) }}</b>
                            </div>
                            <div v-else-if="value3.type == 'file'">
                              <h6>{{ value3["label"] }}</h6>
                              <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input" @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input)">
                                <img src="~@/assets/images/eye.png" alt="" />
                              </button>
                            </div>
                            <div v-else-if="value3.type == 'checkbox'">
                              <h6>
                                <img v-if="value3.input" src="~@/assets/images/check-mark.svg" class="check-markimg" alt=""/> 
                                {{ value3["label"] }}
                              </h6>
                              <!-- <b>{{ value3["input"] }}</b> -->
                            </div>
                            <div v-else>
                              <h6>{{ value3["label"] }}</h6>
                              <b>{{ value3["input"] }}</b>
                            </div>
                          </div>
                        </template>
                      </div>

                      
                      <!-- 333333 -->
                      <template v-for="(value3, ind3) in value.fieldGroups[ind2].fieldGroups" :key="ind3">
                        <fieldset v-if="!value3.visibility?true:checkVisibility(value3.visibility)">
                          <legend>{{ convertSentenceCase(value3["label"]) }}</legend>
                          <div class="pro-details">
                            <template class="" v-for="(value4, ind4) in value3.fields" :key="ind4">
                              <div class="mb-4" v-if="value4.type == 'static'">
                                <div>
                                  <h6>{{ value4["label"] }}</h6>
                                </div>
                              </div>
                              <div class="mb-4" v-if="value4.input != ''">
                                <div v-if="value4.type == 'single-select'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ getSelectValue(value4.value, value4["input"]) }}</b>
                                </div>
                                <div v-else-if="value4.type == 'multi-select'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <!-- <b>{{ getMultiSelectValue(value4.value, value4["input"]) }}</b> -->
                                  <b>{{ value4["input"].toString() }}</b>
                                </div>
                                <div v-else-if="value4.type == 'boolean'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ (value4["input"] == '1')?'Yes':'No' }}</b>
                                </div>
                                <div v-else-if="value4.type == 'date' && value4.input">
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ dateconvert(value4["input"]) }}</b>
                                </div>
                                <div v-else-if="value4.type == 'file'">
                                  <h6>{{ value4["label"] }}</h6>
                                  <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal" data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input" @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input)">
                                    <img src="~@/assets/images/eye.png" alt="" />
                                  </button>
                                </div>
                                <div v-else-if="value4.type == 'checkbox'">
                                  <h6>
                                    <img v-if="value4.input" src="~@/assets/images/check-mark.svg" class="check-markimg" alt=""/> 
                                    {{ value4["label"] }}
                                  </h6>
                                  <!-- <b>{{ value4["input"] }}</b> -->
                                </div>
                                <div v-else>
                                  <h6>{{ value4["label"] }}</h6>
                                  <b>{{ value4["input"] }}</b>
                                </div>
                              </div>
                            </template>
                          </div>
                        </fieldset>
                      </template>
                      <!-- 333333 -->



                    </fieldset>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="otp-section" >
          <div class="text-center" v-if="!sendOtp">
            <button class="next-btn mb-4" @click="getOTP()">Get OTP</button>
          </div>
          <div class="form-control1" v-if="sendOtp">
            <input type="text" v-model="inputedOtp">
            <p class="error-text" v-if="error.inputedOtp">
              {{ error.inputedOtp }}
            </p>
          </div>
          <div v-if="sendOtp" class="d-flex justify-content-between align-items-center">
            <button class="next-btn mb-4" @click="resendOTP()">Resend OTP</button> &nbsp;&nbsp;
            <button class="next-btn mb-4" @click="submitOTP()">Submit OTP</button>
          </div>
          <div>
            <span class="text-success" v-if="otpSuccess">OTP Verified Successfully.</span>
            <span class="text-danger" v-if="otpFailed">Invalid OTP Entered. Please Provide Valid OTP.</span>
          </div>
        </div> -->
        <!-- <div  class="button wrap d-flex justify-content-center">
          <button type="submit" class="next-btn mb-4" @click="buyPolicy()">
            Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
          </button>
        </div> -->
        <div class="error-msg" :innerHTML="errorMsgs">
          <!-- {{ errorMsgs }} -->
        </div>
        <div class="button wrap d-flex justify-content-center">
          <button type="submit" class="next-btn mb-4" @click="buyPolicy()">
            Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
    <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Selected File</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                  <div v-if="!fileToDisplay" class="error_msg text-center doc_upload_block2">No Document Selected</div>
                  <div v-else>
                    <img v-if="typeToDisplay == 'image'" class="image1" :src="fileToDisplay">
                    <iframe v-else width="100%" height="400px" :src="fileToDisplay"></iframe>
                  </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- ./End Modal -->

</div>
</template>


<script>
import moment from 'moment';
import {getPolicyCover, getConpareData, getInsuranceImages, getDistributer} from '@/mixins/common'
import Loading2 from "@/components/loading2.vue";
export default {
  name: 'proposaldetailscheck',
  mixins:[getPolicyCover, getConpareData, getInsuranceImages, getDistributer],
  components: {
    Loading2
  }, 
  mounted() {
    // Getting selected plan
    this.selectedPlan = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
    
    console.log('selected plan: ', this.selectedPlan);
    // Getting previously filled data
    this.formInfo = JSON.parse(sessionStorage.getItem("filledData"));
    if(!this.formInfo) {
      // Redirecction Logic
      
    } else {
      //console.log(this.formInfo)
      console.log('this.form info iii: ', this.formInfo);
      //let containerObject = new JSONObject(this.formInfo);
      //has method
      if (this.formInfo.fieldGroups.otpDetails) {
        //get Value of video
        console.log('otp present');
      } else {
        console.log('otp not present');
      }
    }

    if (sessionStorage.getItem("sp_details_data")) {
      this.spDetails = JSON.parse(sessionStorage.getItem("sp_details_data"));
    }

    if(sessionStorage.getItem('proposal_ui_id')) {
      this.proposalUiId = JSON.parse(sessionStorage.getItem('proposal_ui_id'));
    }

    if(sessionStorage.getItem('uiQuoteId')) {
      this.uiQuoteId = JSON.parse(sessionStorage.getItem('uiQuoteId'));
      console.log('this.uiQuoteId: ', this.uiQuoteId);
    }

    // Setting Distributer ID
    this.distributorID = getDistributer()["distributerID"];
    console.log('this.distributorID: ', this.distributorID);

    let thanksURL= process.env.VUE_APP_URL_RMD
    this.thankYouUrl=thanksURL+"/v1/paymentStatus?proposal_ui_id=" + this.proposalUiId;
    
    

  },
  data() {
    return {
      selectedPlan: "",
      formInfo: "",
      spDetails: "",
      loading: false,
      apiError: false,
      spDetailsError: false,
      thankYouUrl: "",
      proposalUiId: "",
      uiQuoteId: "",
      distributorID: "",
      sendOtp: false,
      resendOtp: false,
      submitOtp: false,
      inputedOtp: "",
      otpSuccess: false,
      otpFailed: false,
      randOtp: "",
      error: [],
      errorMsgs: "",
      typeToDisplay: "",
      fileToDisplay: "",
    } 
  },
  methods: {
    convertSentenceCase(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    getShow(tab) {
      if(tab == 'proposerDetails') {
        return "show";
      } else {
        return '';
      }
    },
    getInsurerImgs(value) {
      let img = getInsuranceImages();
      return require("../../assets/images/" + img[value]);
    },
    sendMailToCustomer()
    {
      console.log(new URL(location).origin+"/#/proposaldetailscheckdiy");
      let rmData = JSON.parse(sessionStorage.getItem("rm_data"));
      console.log(rmData);
      var resumeLink= new URL(location).origin+"/#/proposaldetailscheckdiy?proposalId="+this.proposalUiId+'&transactionId='+rmData['transaction_id'];
      var emailBody = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:th="http://www.thymeleaf.org">
   <head>
      <meta charset="ISO-8859-1" />
      <title>Insert title here</title>
   </head>
   <body>
      <table width="700"border="0"cellspacing="0" cellpadding="0" align="center"  bgcolor="#EEF3FB">
         <tr>
            <td
               style="  font-family: Public Sans; font-weight: 600; font-size: 18px;  color: #1f1f1f; padding: 20px 0px; text-align: center;" >
               <center><img style="max-width: 150px"src="https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/BCIBL+logo.png" ></center>
            </td>
         </tr>
         <tr>
            <td>
               <table width="700" border="0" cellspacing="0" cellpadding="0"  align="center"   bgcolor="#fff" style="border-radius: 5px; background-color: #fff;
                  margin-left: 30px; margin-right: 30px;" >
                  <tr>
                     <td width="50"></td>
                     <td
                        style="
                        font-family: Public Sans; font-weight: 600; font-size: 18px;color: #1f1f1f;padding: 20px 0px 10px 0px;" >
                        Dear ${this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['input']} ${this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['input']}
                     </td>
                     <td width="50"></td>
                  </tr>
                  <tr>
                     <td width="20"></td>
                     <td
                        style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;">
                        Thank you for choosing ${this.selectedPlan["insuranceAndProducts"]["productName"]}. Your application is ready!<br>
						Please review your application. If you are happy with the details, kindly approve the form with OTP verification and make payment.
                        <center>
                           <br/>
                           <a style=" background-color: orange;
                              text-decoration-line: none;
                              padding: 10px;
                              border-radius: 4px;
                              border-color: transparent;
                              color: white;
                              font-weight: bold;" href=${resumeLink}> Review and Pay </a>
                        </center>
                     </td>
                     <td width="50"></td>
                  </tr>
                  <tr>
                     <td width="50"></td>
                     <td style=" border-bottom: 1px solid #f0f0f0; font-size: 14px; color: #1f1f1f; padding: 10px 0px 0px 0px; " ></td>
                     <td width="50"></td>
                  </tr>

                  <tr>
                     <td width="50"></td>
                     <td
                        style="
                        font-family: Public Sans; font-size: 14px;  color: #1f1f1f; padding: 20px 0px; line-height: 22px;" >
                        For any assistance, please feel free to contact us at 1800 212 123123 or <br>
						email info@bajajcapitalinsurance.com
                     </td>
                     <td width="50"></td>
                  </tr>
				  <tr>
                     <td width="50"></td>
                     <td
                        style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;" >
                        Regards,<br>
						Bajaj Capital Insurance
                     </td>
                     <td width="50"></td>
                  </tr>
				  <tr>
                     <td width="50"></td>
                     <td
                        style="font-family: Public Sans; font-size: 10px;  color: #1f1f1f; padding: 20px 0px; line-height: 22px;" >
                        *Insurance is the subject matter of solicitation. IRDAI Insurance Broking License No. 1. License Code No. IRDA/DB 1/22 Valid till 01/01/2023
                     </td>
                     <td width="50"></td>
                  </tr>
               </table>
            </td>
         </tr>
         <tr>
            <td
               style="  font-family: Public Sans; font-size: 12px;  text-align: center; opacity: 0.5; color: #1f1f1f;  padding: 10px; " >
               <center><b style="color: black;"> Powered by </b><strong style="color: blue;font-size: 12px;">9Platforms Technology Pvt Ltd.</strong></center>
            </td>
         </tr>
      </table>
   </body>
</html>`;


      // var text = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
      //             <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      //               <head>
      //                   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      //                   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      //                   <title>Email</title>
      //                   <style type="text/css">@media screen and (min-width: 750px){body{font-family:Arial,Helvetica,sans-serif!important;font-weight:500}body,table,thead,tbody,tr,td,img{padding:0;margin:0;border:none;border-spacing:0px;border-collapse:collapse;vertical-align:top}.wrapper{padding-left:10px;padding-right:10px;padding-top:10px;padding-bottom:10px}h1,h2,h3,h4,h5,h6,p{margin:0;padding:0;padding-bottom:20px;line-height:1.6}img{width:100%;display:block}.main-table{width:710px;margin:0 auto;margin-top:5px}.main-table tr td{font-size:14px!important;line-height:18px!important}.main-table-td{padding:10px!important}.main-table tr td.order-detl-mob{font-size:21px!important}.brand-logo img{width:auto!important}.main-table tr.totl-cost-td td{font-size:18px!important;line-height:18px!important}table{border-spacing:0}.order-details-table td{font-size: 9px!important;}}</style>
      //               </head>
      //               <body cellspacing="0" cellpadding="0" border="0" class="body" style="padding-top:5px !important;padding-bottom:5px !important; margin:0px !important; display:block !important; min-width:100% !important; width:100% !important; -webkit-text-size-adjust:none; background-color: #eceaea; " bgcolor="#eceaea">
      //                   <!--[if !mso]><!-->
      //                   <table align="center" style="background-color: #eceaea;margin-top: 5px;width: 100%;">
      //                     <tr>
      //                         <td>
      //                           <table style="" class="main-table" align="center">
      //                               <tr>
      //                                 <td style="background: #fff;" class="main-table-td">
      //                                     <table width="100%" style="background: #fff;border-collapse: collapse;" align="center" >
      //                                       <tr>
      //                                           <td>
      //                                             <table width="100%" style="border-collapse: collapse;">
      //                                                 <tr>
      //                                                   <td width="20%" class="mobile-td1" style="vertical-align: middle;">
      //                                                       <table border="0">
      //                                                         <tr>
      //                                                             <td class="brand-logo"> <img src="https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/BCIBL+logo.png" alt="" style="width: 100%;"></td>
      //                                                         </tr>
      //                                                       </table>
      //                                                   </td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td height="10px" colspan="2"></td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td height="10px" colspan="2"></td>
      //                                                 </tr>
      //                                             </table>
      //                                           </td>
      //                                       </tr>
      //                                       <tr>
      //                                           <td>
      //                                             <table style="font-size: 9px;border-collapse: collapse;">
      //                                                 <tr></tr>

      //                                                 <tr>
      //                                                   <td>Dear `+this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['input']+`</td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td height="40px"></td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td>Thank you for applying for `+this.formInfo['manufacturerId']+` `+this.formInfo['productId']+`. Based on details provided, please click the link below to review all the details.
      //                                                   </td>
      //                                                                   </tr>
      //                                                 <tr>
      //                                                                     <td height="40px"></td>
      //                                                                   </tr>
      //                                                 <tr>
      //                                                   <td>Kindly review all the details and in case of any error or change, please do not hesitate to contact us.
      //                                                   </td>
      //                                                 <tr>
      //                                                 <tr>
      //                                                   <td height="20px"></td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td> <a href="`+ resumeLink +`">Click To Proceed</a>
      //                                                   </td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td height="40px"></td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td>If you are happy with the details, kindly approve the form by signing with OTP and making payment.</td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td height="40px"></td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td>For any clarification, kindly contact your relationship manager at</td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td>`+rmData['Email']+` `+rmData['Mobile']+`</td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td height="40px"></td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td>Regards,</td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td>BCIBL</td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td height="50px"></td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td style="font-size: 9px;border-collapse: collapse;">*Insurance is the subject matter of solicitation. IRDAI Insurance Broking License No. 1. License Code No. IRDA/DB 1/22 Valid till 01/01/2023</td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td height="50px"></td>
      //                                                 </tr>
      //                                                 <tr>
      //                                                   <td><center>Powered by 9Platforms Technology Pvt Ltd.</center></td>
      //                                                 </tr>
      //                                             </table>
      //                                           </td>
      //                                       </tr>
                                            
                                            
      //                                     </table>
      //                                 </td>
      //                               </tr>
      //                           </table>
      //                         </td>
      //                     </tr>
      //                   </table>
      //               </body>
      //             </html>`
        let qData = {
          "send_from":"customer.service@1silverbullet.tech",
          "subject":"Your application for "+this.selectedPlan["insuranceAndProducts"]["productName"],
          "text": emailBody,
          "send_to":[this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['email']['input']]
        // this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['email']['input']
        };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        this.backendApi("sendMail", qData, headers)
          .then((response) => {
            //console.log('save quote response: ', response);
            this.loading = false;
            if(response == 'error') {
              self.apiError = true;
            let msg=['We are facing some issue while sending an e-mail']

            this.$router.push({ path: '/alert', query: {backUrl:'proposal-details-check', msg: msg }});
              // alert("Error occurred while sending Mail. Please try again!");
            }
            else if(response.status.error=="error")
            {
            let msg=['We are facing some issue while sending an e-mail']

            this.$router.push({ path: '/alert', query: {backUrl:'proposal-details-check', msg: msg }});
            }
             else if(response && response.data){
              
              this.$router.push('/thankyoumail');
              //window.location.href = new URL(location).origin
              
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
          let msg=['We are facing some issue from backend while sending an e-mail']

            this.$router.push({ path: '/alert', query: {backUrl:'proposal-details-check', msg: msg }});
          });
    },

    buyPolicy() {

      //let diy_flag= process.env.VUE_DIY_JOURNEY
      let diy_flag = 0;
      // if(diy_flag == undefined)
      //   diy_flag =1;
      if(this.formInfo['manufacturerId']=="TATA"||this.formInfo['manufacturerId']=="ICICI")
      {
        this.loading = true;
        diy_flag =1;
      }
      if(diy_flag == 1)
      {
        if(this.formInfo['manufacturerId']=="ICICI")
        {
          this.validateProposal();
        }
        else
        {
          this.updateProposalStatus(11);
          this.sendMailToCustomer();
        }        
      }
      else
      {
      
        if(this.spDetails.source) {

          var self = this;
          this.errorMsgs = "";
          this.selectedPlan = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
          //let productApiUrl = this.selectedPlan.insuranceAndProducts.insuranceCompanyCode + '/' + this.selectedPlan.insuranceAndProducts.productCode;

          //specific to ipru
          // this.formInfo.fieldGroups.other.fields.partnerThankYouUrl['input'] = this.thankYouUrl;
          // this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input = parseInt(this.formInfo.fieldGroups.proposerDetails.fieldGroups.professionalDetails.fields.annualIncome.input);
          // this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input = parseInt(this.formInfo.fieldGroups.firstLifeAssuredDetails.fieldGroups.professionalDetails.fields.annualIncome.input);

          let reqData = {"data":{
            "quoteResponse": { "data": { "quote": [ this.selectedPlan ],"request":JSON.parse(sessionStorage.getItem('quote_request'))}},
            "data": this.formInfo
          }};

          // let headers = {
          //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_PMSNPASS),
          //   "Content-Type": "application/json",
          // };
          let headers ={};
          
          this.loading = true;
          this.backendApi("proposalVersionApi", reqData, headers)
            .then((response) => {
              self.loading = false;
              self.apiError = false;
              if(response.data.errors){
                if(response.data.errors.length > 0)
                {
                  //Update proposal status to "Proposal Error"
                  this.updateProposalStatus(12);
                  //this.saveProposalResponse(reqData,response.data);
                  //self.apiError = true;
                  
                  let ck = 1;
                  response.data.errors.forEach(function(element) {
                    self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                    ck++;
                  });
                }
                else
                {
                  if(response['status'] == 'error') {
                    //Update proposal status to "Proposal Error"
                    this.updateProposalStatus(12);
                    //this.saveProposalResponse(reqData,response.data);
                    //self.apiError = true;
                    
                    let ck = 1;
                    response.data.errors.forEach(function(element) {
                      self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                      ck++;
                    });

                  } else if(response && response.data) {
                    //Update proposal status to "Proposal Submitted"
                    this.updateProposalStatus(9);
                    //this.saveProposalResponse(reqData,response.data);
                    console.log('Submit proposal response: ', response.data)
                    self.loading = false;
                    //this.response = response.data;

                    let reqData2 = [this.selectedPlan.insuranceAndProducts.productCode, this.selectedPlan.insuranceAndProducts.insuranceCompanyCode, response.data.reqId];
                    this.reqTimeOut();
                    this.getProposalPoll(reqData2);
                    
                  }
                }
              }
              else
              {
                if(response['status'] == 'error') {
                  //Update proposal status to "Proposal Error"
                  this.updateProposalStatus(12);
                  //this.saveProposalResponse(reqData,response.data);
                  //self.apiError = true;
                  console.log('inside error: ', response.data);
                  let ck = 1;
                  response.data.errors.forEach(function(element) {
                    self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                    ck++;
                  });

                } else if(response && response.data) {
                  //Update proposal status to "Proposal Submitted"
                  this.updateProposalStatus(9);
                  //this.saveProposalResponse(reqData,response.data);
                  console.log('Submit proposal response: ', response.data)
                  self.loading = false;
                  //this.response = response.data;

                  let reqData2 = [this.selectedPlan.insuranceAndProducts.productCode, this.selectedPlan.insuranceAndProducts.insuranceCompanyCode, response.data.reqId];
                  this.reqTimeOut();
                  this.getProposalPoll(reqData2);
                  
                }
              }


              // console.log('Sumbit proposal success', response);
              // self.loading = false;
              // self.apiError = false;
              // if(response['status'] == 'error') {
              //   //self.apiError = true;
              //   console.log('inside error: ', response.data);
              //   let ck = 1;
              //   response.data.errors.forEach(function(element) {
              //     self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
              //     ck++;
              //   });

              // } else if(response && response.data) {
              //   console.log('Submit proposal response: ', response.data)
              //   self.loading = false;
              //   //this.response = response.data;

              //   let reqData2 = [this.selectedPlan.insuranceAndProducts.productCode, this.selectedPlan.insuranceAndProducts.insuranceCompanyCode, response.data.reqId];
              //   this.reqTimeOut();
              //   this.getProposalPoll(reqData2);
                
              // }
            })
            .catch((error) => {
              console.log('error msg block submit proposal', error);
              self.loading = false;

            });

        } else {
          this.$router.push({ path: '/alert', query: { status: 'Error',backUrl:"proposal-details-check", msg: 'SP Details not available' }});

          // alert('SP Details not available');
        }
      }
    },
    updateProposalStatus(statusid)
    {
        let qData = {
          "proposal_ui_id":this.proposalUiId,
          "status_id": statusid
          };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
          };
        
        this.backendApi("ProposalStatus", qData, headers)
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
            let msg=['We are facing some issue while saving proposal status']

            this.$router.push({ path: '/alert', query: {backUrl:'proposal-details-check', msg: msg }});
            }
            else if(response.status=="error")
            {
            let msg=['We are facing some issue while saving proposal status']

            this.$router.push({ path: '/alert', query: {backUrl:'proposal-details-check', msg: msg }});
            }
             else if(response && response.data) {
              console.log('proposal status updated: ', response.data);
              //Redirect to customer with Mail
              this.sendMailToCustomer();
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
            let msg=['We are facing some issue from backend while saving proposal status']

            this.$router.push({ path: '/alert', query: {backUrl:'proposal-details-check', msg: msg }});
          });
    },
    getProposalPoll(reqData) {
      let self = this;
      console.log('get proposal called: ', reqData);
      // let headers = {
      //   Authorization: "Basic " + window.btoa(process.env.VUE_APP_PMSNPASS),
      //   "Content-Type": "application/json",
      // };
      let headers = {};
      this.loading = true;

      setTimeout(() => {
        console.log("set time out called")
          this.backendApi("proposalPollApi", reqData, headers, "get")
          .then((response) => {
            console.log('Get proposal poll success');
            //self.loading = false;
            self.apiError = false;
            if(response.errors)
            {
              if(response.errors.length > 0){
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                clearTimeout(this.timer);
                self.apiError = true;
                self.loading = false;
                let ck = 1;
                response.errors.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                  ck++;
                });
              }
            }
            if(response.error)
            {
              if(response.error.length > 0){
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                clearTimeout(this.timer);
                self.apiError = true;
                self.loading = false;
                let ck = 1;
                response.error.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                  ck++;
                });
              }
            }
            if(response.data.errors)
            {
              if(response.data.errors.length > 0){
                //Update proposal status to "Proposal Error"
                this.updateProposalStatus(12);
                clearTimeout(this.timer);
                self.apiError = true;
                self.loading = false;
                let ck = 1;
                response.data.errors.forEach(function(element) {
                  self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                  ck++;
                });
              }
            }
            if(response == 'error') {
              //Update proposal status to "Proposal Error"
              this.updateProposalStatus(12);
              self.apiError = true;
              self.loading = false;
            } else if(response && response.data){
              console.log('Get proposal poll response: ', response.data)

              if(response.data?.data?.message) {
                
                //console.log('nside internal message');
                if(response.data.data.message == 'Please wait we are still processing the request') {
                  self.getProposalPoll(reqData);
                }

              } else if(response.data?.status) {
                if(response.data.status.message == 'Success' || response.data.status.code == 'E00') {
                  clearTimeout(this.timer);
                  self.loading = false;
                  
                  let productCode_DIY;
                  if(sessionStorage.getItem('filledData')) {
                    productCode_DIY = JSON.parse(sessionStorage.getItem("filledData"));
                  }
                  
                  if(productCode_DIY["manufacturerId"]=="HDFC")
                  {
                    this.thankYouUrl = new URL(location).origin+"/#/thankyoupage";
                    window.location.href = this.thankYouUrl;
                  }
                  
                } else {
                  
                  self.loading = false;
                  this.lastresponse = JSON.stringify(response.data.status);
                  clearTimeout(this.timer);
                  this.$router.push({ path: '/alert', query: { status: 'Error',backUrl:"proposal-details-check", msg: "Received Error response from manufacturer: "+this.lastresponse }});

                  // alert("Received Error response from manufacturer: "+this.lastresponse)
                  //Updating proposal status tp 'Proposal Error'
                  this.updateProposalStatus(12);
                  this.sendMailToCustomerError();
                  window.location.href = new URL(location).origin + "/#/proposaldetailscheckdiy?proposalId="+this.$route.query.proposalId+"&transactionId="+this.$route.query.transactionId;
                  //self.getProposalPoll(reqData);
                }
              }   
            }
          })
          .catch((error) => {
            self.loading = false;
            console.log('error msg block get proposal poll', error);

          });
      }, 10000);
    },
    checkVisibility(value) {
      //return eval(value);
      if(value) {
        let obj = this.getFinalObject(value);
        try {
          return eval(obj);
        }
        catch(err) {
          console.log('error exp: ', value);
        }
      } else {
        return true;
      }
    },
    getFinalObject(tempResponse) {
      //console.log('tempResponse: ', tempResponse)
      var tempString = JSON.stringify(tempResponse);
      var updatedString = tempString.replace(/'input' in formInfo\[/g, "formInfo[");
      var updatedString2 = updatedString.replace(/formInfo\[/g, "this.formInfo[");
      //var updatedString3 = updatedString2.replace(/this.this./g, "this.");
      //console.log('final String: ', updatedString3);
      return JSON.parse(updatedString2); 
    },
    editDetails(tabIndex) {
      if(confirm("Do you really want to edit these details?")){
        localStorage.setItem("filledDataTab", JSON.stringify(tabIndex));
        sessionStorage.setItem("filledDataTab", JSON.stringify(tabIndex));
        this.$router.push('/proposal');
      }
    },
    getSelectValue(array, val) {
      if(array) {
        let itemIndex = array.findIndex(item => item.Value == val);
        if(array[itemIndex]) {
         return array[itemIndex]['Text'];
        }
      } else {
        return '';
      }
    },
    getMultiSelectValue(array, array2) {
      console.log('getMultiSelectValue called: ', array, array2);
      if(array2) {
        let tmpVal = [];
        for(var i = 0; i < array2.length; i++) {
          var x = this.getSelectValue(array, array2[i]);
          if(x) {
            tmpVal.push(x);
          }
        }
        return tmpVal;
      }
    },
    dateconvert(value){
      const formattedDate = moment(value).format('DD/MM/YYYY')
     // console.log(formattedDate)
      return formattedDate;
    },
    getOTP() {
      console.log('get otp called');

      let otpReq = {
        "data":{
        "additionalSetup":{		
          "userCountry":"",	
          "userRegion":"",
          "userLanguage":"",
          "userIP":"",
          "userAgent":"",
          "userGeoLocation":{	
            "latitude":"",
            "longitude":""
          }
        },
        "distributor":{
          "distributorID": this.distributorID,
          "agentID":"",
          "salesChannel":"",	
          "varFields":[{
            "fieldName":"",
            "fieldValue":""
          }]
        },
        "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,	
        "applicationNo":"",
        "policyNo":"",
        "quoteId": this.uiQuoteId,
        "UITrackingRefNo": this.proposalUiId,
        "OTPDetails":{	
          "mobileNumber": this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input,	
          "email":""
        },
        "varFields":[{		
          "fieldName":"",
          "fieldValue":""
        }]
      }};

      console.log('otpReq: ', otpReq);

      this.loading = true;
      let headers = {};
      this.backendApi("sendOtpApi", otpReq, headers)
        .then((response) => {
          this.loading = false;
          console.log('get otp response', response);
        })
        .catch((error) => {
          console.log('error msg block get otp on proposal confirmation', error);
          self.loading = false;
        });

      /* let smsUserName = process.env.VUE_APP_SMS_USERNAME;
      let smsPassword = process.env.VUE_APP_SMS_PASSWORD;
      let smsFrom = process.env.VUE_APP_SMS_FROM;
      let smsUdh = process.env.VUE_APP_SMS_UDH;
      this.randOtp = Math.floor(100000 + Math.random() * 900000);
      let mobileNumber = this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input;
      let msgText = 'Hi ' + this.formInfo.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.firstName.input +', \n Your life term OTP is: ' + this.randOtp + '';
      let headers = {};
      let reqData = [
        smsUserName,
        smsPassword,
        mobileNumber,
        smsFrom,
        smsUdh,
        msgText
      ];

      console.log('Mobile: ', mobileNumber, 'Msg: ', msgText);
       this.backendApi("sendSMS", reqData, headers, "get")
          .then((response) => {
            console.log('Send SMS success: ', response);
          })
          .catch((error) => {
          self.loading = false;
          console.log('error msg block send sms', error);
        });
      this.sendOtp = true; */
    }, 
    resendOTP() {
      console.log('resend OTP called');
      this.resendOtp = true;
    },
    submitOTP() {
      console.log('submit otp called');

      let submitOtpReq = {
        "distributor":{
          "distributorID": this.distributorID,
          "agentID":"",
          "salesChannel":"",	
          "varFields":[{
            "fieldName":"",
            "fieldValue":""
          }]
        },
        "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,	
        "applicationNo":"",
        "policyNo":"",
        "quoteId": this.uiQuoteId,	
        "OTPDetails":{
          "OTP":"",	
          "OTPTransactionId":""
        },
        "varFields":[{
          "fieldName":"",
          "fieldValue":""
        }]
      };

      console.log('submitOtpReq: ', submitOtpReq);
      this.loading = true;
      let headers = {};
      this.backendApi("submitOtp", submitOtpReq, headers)
        .then((response) => {
          this.loading = false;
          console.log('submit otp response', response);
        })
        .catch((error) => {
          console.log('error msg block get otp on proposal confirmation', error);
          self.loading = false;
        });

      /* this.error = []; 
      if(this.inputedOtp.length > 0) {
        this.submitOtp = true;
        this.otpSuccess = true;
        console.log('inputed OTP: ', this.inputedOtp);
      } else {
        this.error["inputedOtp"] = "Please Enter OTP";
      } */
    },
    reqTimeOut() {
      console.log('request timeout called');
      this.timer = setTimeout(() => {
        this.$router.push({ path: '/alert', query: { status: 'Error',backUrl:"proposal-details-check", msg: "No response received, Please try again" }});

        // alert('No response received, Please try again.');
        this.$router.go();
      }, 180000);
    },
    previewFiles(imgData) {
      console.log('previewFiles called: ', imgData)
      const type = imgData.split(';')[0].split('/')[0].split(':')[1];
      console.log('imgData type:', type);
      this.typeToDisplay = type;
      this.fileToDisplay = imgData;
    },
    validateProposal()
    {
      var self = this;
      self.errorMsgs = "";
      this.selectedPlan = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
      
      let reqData = {
        "data":
        {
          "quoteResponse": { "data": { "quote": [ this.selectedPlan ],"request":JSON.parse(sessionStorage.getItem('quote_request'))}},
          "data": this.formInfo
        }
      };

      let headers = {};      
      this.loading = true;
      this.backendApi("validateProposal", reqData, headers)
        .then((response) => {
             
          //this.loading = false;
          self.apiError = false;
          if(response.data.errors){
            if(response.data.errors.length > 0)
            {
              this.loading = false;
              self.apiError = true;
              let ck = 1;
              let msg=[]
              for(let i=0;i<response.data.errors.length;i++)
              {
                  msg.push(response.data.errors[i]["errorMessage"])
              }
              
            this.$router.push({ path: '/alert', query: {backUrl:'proposal-details-check', msg:msg  }});

              response.data.errors.forEach(function(element) {
                console.log("errorMsg",response)
                self.errorMsgs += ck +'. '+ element.errorMessage +'<br/>';
                ck++;
              });
            }
            else
            {
              if(response && response.data) {
                // Updating status to Awaiting Client Approval
                this.updateProposalStatus(11);
              }
            }
          }
          
        })
        .catch((error) => {
          console.log('error msg block submit proposal', error);
          self.loading = false;
          let msg=['We are facing some issue from backend while validating proposal data']

            this.$router.push({ path: '/alert', query: {backUrl:'proposal-details-check', msg: msg }});

        });
    }

  },
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/checkout.scss';
@import '~@/assets/scss/components/proposal-page.scss';
</style>
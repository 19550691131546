<template>
<body>
  <div class="loading_block_outer" v-if="loading">
    <Loading2 />
  </div>
  <div v-else class="container">
    <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto ">
        <!-- <img class="brand-logo" src="~@/assets/images/logo.jpg" alt="" /> -->
        </div>
        </div>
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 shadow rounded-3 my-5">
          <div class="card-body p-4 p-sm-5">
            <form>
              <div class="form-floating mb-3">
                <label for="floatingInput">Email </label>
                <input type="email" class="form-control" @change="validateEmail()" v-model="email" id="floatingInput" placeholder="Please enter your email here">
                <label class="errormsg" >{{this.errormsg}}</label>
              </div>
              <div class="form-floating mb-3">
                <label for="floatingPassword">Password</label>
                <input v-if="show" type="text" class="form-control" v-model="password" autocomplete="off" id="floatingPassword" placeholder="Password">
                <input v-else type="password" class="form-control" v-model="password" autocomplete="off" id="floatingPassword" placeholder="Password">

            </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input"  @change="showPassword()" id="showPass">
                <label class="custom-control-label" for="showPass">Show Password</label>
                </div>
                <label class="errormsg">{{this.errormsglogin}}</label>
              <div class="d-grid">
                <button class="btn btn-primary btn-login text-uppercase fw-bold" @click="login()" type="submit">Sign
                  in</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
</template>
<script>
import jwt_decode from "jwt-decode";
import Loading2 from "@/components/loading.vue";
export default {
  name: "personalLoanLogin",
  components: {
     Loading2
  },
  data(){
    return{
      loading:false,
    email: "",
    password: "",
    show: false,
    errormsg:"",
    errormsglogin:"",
    token:"",
    decoded:"",
    current_user:"",

    };
  },

  methods: {
    showPassword() {
      this.show = !this.show;
    },
    validateEmail() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,64})+$/.test(this.email)) {
        this.errormsg = "";
    } else {
        this.errormsg= "Please enter a valid email address";
       
    }
    },
    login(){
      if(!this.email || !this.password){
        this.errormsg = !this.email ? "Please enter a valid email address": "";
        this.errormsglogin = !this.password ? "Please enter password": "";      
      }
      else{
        // this.$router.push("/personal-loan");
        this.loading=true
        let qData={
            "distributorID":"PINC",
            "email": this.email,
            "password": this.password,
            "authUsing": "basicAuth",
            "insuranceCompanyCode": "ICICI",
            "UITrackingRefNo": "ed1bbbd2-d3fc-4df9-befe-952d46996958",
            "OTPTransactionId":"",
            "OTP":""
        };

        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        let getParam=[]
        this.backendApi("userLogin", qData, getParam, headers)
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
            }
             else if(response && response.data){
              
                
                if(response.data.errors.length==0){ 
                this.token = response.data.data.token.access_token;
                sessionStorage.setItem('access_token',JSON.stringify((this.token)));
                try{
                    this.decoded = jwt_decode(this.token)
                    this.loading=false
                    this.current_user = this.decoded;
                    console.log("users",this.current_user)
                    sessionStorage.setItem("current_user",JSON.stringify(this.current_user))
                    if(this.current_user.PAYLOAD.userId=="8925")
                     this.$router.push("/personal-loan");
                    // let transId = this.current_user.userId.transaction_id
                   
                    
                }
                catch(err){
                  this.loading=false
                    console.log('token is null: ',err);
                }
                }
                else{
                  this.loading=false
                    this.errormsglogin= response.data.errors[0].errorMessage
                }
            }
          })
          .catch((error) => {
            this.loading=false
            console.log('error msg block email', error);
          });
      }

      }
      

  },
  mounted(){
     if(sessionStorage.getItem("current_user"))
   {
    this.$router.push('/personal-loan')
    // this.rmData=JSON.parse(localStorage.getItem("rm_data"))
   }
   else{
    this.$router.push('/personal-loan-login')
   
   }
  },
};
</script>
<style scoped lang="scss">
.clear{
    height:35%;
}
.errormsg{
    color:#fa9d17;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color:#fa9d17 !important;
  border-color: #fa9d17 !important;
}
body{
    margin-top: 10%;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}
.brand-logo{
    margin-left:10%;
    width: 90%;
    // height: 55px ;
}
.btn-login {
  margin-top: 10%;  
  width:100%;  
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  background:#fa9d17 !important;
  border-color: #fa9d17 !important;
}
</style>

const PROPOSALJSON={
    "proposerDetails": {
        "id": 24,
        "name": "proposerDetails",
        "description": "Apply For Personal Loan",
        "parent": null,
        "label": "Apply For Personal Loan",
        "type": null,
        "sizeOfArray": null,
        "visibility": null,
        "validation": null,
        "order": 3,
        "fieldGroups": {
            "personalDetails": {
                "id": 25,
                "name": "personalDetails",
                "description": "Personal Details",
                "parent": 24,
                "label": "Personal Loan Details",
                "type": null,
                "sizeOfArray": null,
                "visibility": null,
                "validation": null,
                "order": 1,
                "fields": {
                    "relationWithLA": {
                        "id": "1392",
                        "label": "Your Employment Type",
                        "name": "relationWithLA",
                        "description": "Relationship of proposer with first or primary life assured.",
                        "mandatory": 1,
                        "type": "single-select",
                        "value": [
                            {
                                "Text": "Salaried",
                                "Value": "Salaried"
                            },
                            {
                                "Text": "Unemployed",
                                "Value": "Unemployed"
                            },
                            {
                                "Text": "Self-Employed",
                                "Value": "Self-Employed"
                            },
                            
                        ],
                        "default": null,
                        "min": null,
                        "max": null,
                        "pattern": null,
                        "input": "",
                        "visibility": null,
                        "validation": ""
                    },
                    "title": {
                        "id": "198",
                        "label": "Your Current City of residence",
                        "name": "title",
                        "description": "Choose appropriate Title to define the Gender of member",
                        "mandatory": 1,
                        "type": "single-select",
                        "value": [
                            {
                                "Text": "Pune",
                                "Value": "Pune"
                            },
                            {
                                "Text": "Mumbai",
                                "Value": "Mumbai"
                            },
                            {
                                "Text": "Nagpur",
                                "Value": "Nagpur"
                            },
                            {
                                "Text": "Raipur",
                                "Value": "Raipur"
                            },
                            
                        ],
                        "default": "",
                        "min": null,
                        "max": null,
                        "pattern": null,
                        "input": "",
                        "visibility": ""
                    },
                    "firstName": {
                        "id": "199",
                        "label": "Your desired loan amount",
                        "name": "firstName",
                        "description": "No special characters allowed #Name should be exactly as per valid Id Proof.",
                        "mandatory": 1,
                        "type": "text",
                        "value": null,
                        "min": 1,
                        "max": 50,
                        "pattern": "",
                        "input": "",
                        "visibility": null,
                        "validation": ""
                    },
                    "middleName": {
                        "id": "193",
                        "label": "Your Net Monthly Income		",
                        "name": "middleName",
                        "description": "No special characters allowed # Middle Name should be exactly as per valid Id Proof.",
                        "mandatory": 0,
                        "type": "text",
                        "value": null,
                        "default": "",
                        "min": 1,
                        "max": 50,
                        "pattern": "",
                        "input": "",
                        "visibility": "",
                        "validation": ""
                    },
                    "lastName": {
                        "id": "200",
                        "label": "Your Company Name	",
                        "name": "lastName",
                        "description": "No special characters allowed #Last Name should be exactly as per valid Id Proof",
                        "mandatory": 1,
                        "type": "text",
                        "value": null,
                        "min": 1,
                        "max": 50,
                        "pattern": "/^[A-Za-z\\\\s]+$/",
                        "input": "",
                        "visibility": "",
                        "validation": ""
                    },
                    "gender": {
                        "id": "201",
                        "label": "Select Your Gender",
                        "name": "gender",
                        "description": "Choose appropriate title for the Gender",
                        "mandatory": 1,
                        "type": "single-select",
                        "value": [
                            {
                                "Text": "Female",
                                "Value": "F"
                            },
                            {
                                "Text": "Male",
                                "Value": "M"
                            }
                        ],
                        "min": null,
                        "max": null,
                        "pattern": null,
                        "input": "",
                        "visibility": ""
                    },
                    "dateOfBirth": {
                        "id": "1407",
                        "label": "Enter Your Date Of Birth",
                        "name": "dateOfBirth",
                        "description": "Provide as per valid id proof #No future dates allowed",
                        "mandatory": 1,
                        "type": "date",
                        "value": null,
                        "min": null,
                        "max": 20,
                        "pattern": null,
                        "input": "",
                        "visibility": null
                    },
                    "placeOfBirth": {
                        "id": "209",
                        "label": "Your Total Work Experience",
                        "name": "placeOfBirth",
                        "description": "Place of birth cannot contain special characters or numbers #Type your place of birth",
                        "mandatory": 1,
                        "type": "text",
                        "value": null,
                        "default": "",
                        "min": 1,
                        "max": 100,
                        "pattern": "",
                        "input": "",
                        "visibility": ""
                    },
                    "ftitle": {
                        "id": "1418",
                        "label": "Select Your Education",
                        "name": "ftitle",
                        "description": "Choose appropriate Title to define the Gender of member",
                        "mandatory": 1,
                        "type": "single-select",
                        "value": [
                            {
                                "Text": "BE",
                                "Value": "BE"
                            },
                            {
                                "Text": "BTECH",
                                "Value": "BTECH"
                            },
                            {
                                "Text": "MTECH",
                                "Value": "MTECH"
                            }
                        ],
                        "default": null,
                        "min": null,
                        "max": null,
                        "pattern": null,
                        "input": "",
                        "visibility": "",
                        "validation": ""
                    },
                    "fatherName": {
                        "id": "203",
                        "label": "Total EMI You Pay Currently",
                        "name": "fatherName",
                        "description": "Name cannot contain special characters or numbers #Name should be exactly as per valid Id Proof. Please mention full name",
                        "mandatory": 1,
                        "type": "text",
                        "value": null,
                        "default": "",
                        "min": 1,
                        "max": 100,
                        "pattern": "/^[A-Za-z\\s]+$/",
                        "input": "",
                        "visibility": ""
                    },
                    // "mtitle": {
                    //     "id": "2183",
                    //     "label": "Title",
                    //     "name": "mtitle",
                    //     "description": "Choose appropriate Title to define the Gender of member",
                    //     "mandatory": 1,
                    //     "type": "single-select",
                    //     "value": [
                    //         {
                    //             "Text": "Dr",
                    //             "Value": "Dr"
                    //         },
                    //         {
                    //             "Text": "Mrs",
                    //             "Value": "Mrs"
                    //         },
                    //         {
                    //             "Text": "Smt",
                    //             "Value": "Smt"
                    //         }
                    //     ],
                    //     "default": null,
                    //     "min": null,
                    //     "max": null,
                    //     "pattern": null,
                    //     "input": "",
                    //     "visibility": null,
                    //     "validation": ""
                    // },
                    "motherName": {
                        "id": "195",
                        "label": "Enter Your Full Name",
                        "name": "motherName",
                        "description": "Name cannot contain special characters or numbers #Name should be exactly as per valid Id Proof. Please mention full name",
                        "mandatory": 1,
                        "type": "text",
                        "value": null,
                        "default": "",
                        "min": 1,
                        "max": 100,
                        "pattern": "/^[A-Za-z\\s]+$/",
                        "input": "",
                        "visibility": ""
                    },
                    // "maritalStatus": {
                    //     "id": "204",
                    //     "label": "Marital Status",
                    //     "name": "maritalStatus",
                    //     "description": "Select your correct marital status",
                    //     "mandatory": 1,
                    //     "type": "single-select",
                    //     "value": [
                    //         {
                    //             "Text": "Divorced",
                    //             "Value": "divorced"
                    //         },
                    //         {
                    //             "Text": "Married",
                    //             "Value": "married"
                    //         },
                    //         {
                    //             "Text": "Single",
                    //             "Value": "single"
                    //         },
                    //         {
                    //             "Text": "Widowed",
                    //             "Value": "widowed"
                    //         }
                    //     ],
                    //     "default": "",
                    //     "min": null,
                    //     "max": null,
                    //     "pattern": null,
                    //     "input": "",
                    //     "visibility": ""
                    // },
                    // "stitle": {
                    //     "id": "2625",
                    //     "label": "Title",
                    //     "name": "stitle",
                    //     "description": "Choose appropriate Title to define the Gender of member",
                    //     "mandatory": 1,
                    //     "type": "single-select",
                    //     "value": [
                    //         {
                    //             "Text": "Dr",
                    //             "Value": "Dr"
                    //         },
                    //         {
                    //             "Text": "Mr",
                    //             "Value": "Mr"
                    //         },
                    //         {
                    //             "Text": "Ms",
                    //             "Value": "Ms"
                    //         },
                    //         {
                    //             "Text": "Shri",
                    //             "Value": "Shri"
                    //         },
                    //         {
                    //             "Text": "Smt",
                    //             "Value": "Smt"
                    //         }
                    //     ],
                    //     "default": "",
                    //     "min": null,
                    //     "max": null,
                    //     "pattern": null,
                    //     "input": "",
                    //     "visibility": ""
                    // },
                    "spouseName": {
                        "id": "196",
                        "label": "Your Tenure in the Company",
                        "name": "spouseName",
                        "description": "Name cannot contain special characters or numbers #Name should be exactly as per valid Id Proof. Please mention full name",
                        "mandatory": 1,
                        "type": "text",
                        "value": null,
                        "default": "",
                        "min": 1,
                        "max": 100,
                        "pattern": "/^[A-Za-z\\s]+$/",
                        "input": "",
                        "visibility": ""
                    },
                    "mobileNumber": {
                        "id": "2645",
                        "label": "Mobile Number",
                        "name": "mobileNumber",
                        "description": "Mobile number can only have numbers #Please enter 10 digits for Indian Number and 15 for NRI/OCI/PIO/FN",
                        "mandatory": 1,
                        "type": "text",
                        "value": null,
                        "min": 10,
                        "max": 10,
                        "pattern": null,
                        "input": "",
                        "visibility": null,
                        "validation": ""
                    },
                    "email": {
                        "id": "218",
                        "label": "Enter Your Email Address",
                        "name": "email",
                        "description": "Email ID of proposer Example abc@example.com ",
                        "mandatory": 1,
                        "type": "text",
                        "value": null,
                        "default": "",
                        "min": 1,
                        "max": 100,
                        "pattern": "/[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}/",
                        "input": "",
                        "visibility": "",
                        "validation": ""
                    },
                    // "tenure": {
                    //     "id": "2197",
                    //     "label": "Your Tenure in the Company",
                    //     "name": "tenure",
                    //     "description": "Email ID of proposer Example abc@example.com ",
                    //     "mandatory": 1,
                    //     "type": "text",
                    //     "value": null,
                    //     "default": "",
                    //     "min": 1,
                    //     "max": 100,
                    //     "pattern": "/[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}/",
                    //     "input": "",
                    //     "visibility": "",
                    //     "validation": ""
                    // },
                    // "nationality": {
                    //     "id": "205",
                    //     "label": "Nationality",
                    //     "name": "nationality",
                    //     "description": "What is your Nationality?",
                    //     "mandatory": 1,
                    //     "type": "single-select",
                    //     "value": [
                    //         {
                    //             "Text": "Indian",
                    //             "Value": "indian"
                    //         },
                    //         {
                    //             "Text": "Non Indian",
                    //             "Value": "nonIndian"
                    //         }
                    //     ],
                    //     "default": "",
                    //     "min": null,
                    //     "max": null,
                    //     "pattern": null,
                    //     "input": "",
                    //     "visibility": "",
                    //     "validation": ""
                    // },
                    "residentStatus": {
                        "id": "206",
                        "label": "Residence Type",
                        "name": "residentStatus",
                        "description": "Select your resident status",
                        "mandatory": 1,
                        "type": "single-select",
                        "value": [
                            {
                                "Text": "Foreign National",
                                "Value": "foreignNational"
                            },
                            {
                                "Text": "NRI",
                                "Value": "NRI"
                            },
                            {
                                "Text": "OCI",
                                "Value": "oci"
                            },
                            {
                                "Text": "PIO",
                                "Value": "pio"
                            },
                            {
                                "Text": "Resident Indian",
                                "Value": "residentIndian"
                            }
                        ],
                        "min": null,
                        "max": null,
                        "pattern": null,
                        "input": "",
                        "visibility": "",
                        "validation": ""
                    },
                    "panNumber": {
                        "id": "208",
                        "label": "Your PAN Number",
                        "name": "panNumber",
                        "description": "Incorrect PAN Pattern #Provide valid PAN as per document",
                        "mandatory": 1,
                        "type": "text",
                        "value": null,
                        "default": "",
                        "min": 10,
                        "max": 10,
                        "pattern": "/^([A-Z]{5}[0-9]{4}[A-Z]{1})$/",
                        "input": "",
                        "visibility": "",
                        "validation": ""
                    },
                    "panNumber": {
                        "id": "208",
                        "label": "Residence Pin Code",
                        "name": "panNumber",
                        "description": "Incorrect PAN Pattern #Provide valid PAN as per document",
                        "mandatory": 1,
                        "type": "text",
                        "value": null,
                        "default": "",
                        "min": 10,
                        "max": 10,
                        "pattern": "/^([A-Z]{5}[0-9]{4}[A-Z]{1})$/",
                        "input": "",
                        "visibility": "",
                        "validation": ""
                    },
                    "pepFlag": {
                        "id": "235",
                        "label": "Have You Taken Loan in the Past?",
                        "name": "pepFlag",
                        "description": "Are you a Politically Exposed Person?",
                        "mandatory": 1,
                        "type": "boolean",
                        "value": null,
                        "default": "",
                        "min": null,
                        "max": null,
                        "pattern": null,
                        "input": "",
                        "visibility": "",
                        "validation": ""
                    },
                    "historyOfConviction": {
                        "id": "211",
                        "label": "Chew Or Smoke Tobacco	?",
                        "name": "historyOfConviction",
                        "description": "Do you have any History of conviction ?",
                        "mandatory": 1,
                        "type": "boolean",
                        "value": null,
                        "default": "",
                        "min": null,
                        "max": null,
                        "pattern": null,
                        "input": "",
                        "visibility": "",
                        "validation": ""
                    }
                }
            }
        }
    }
}
const TEST="fdsfgdsjkgjk"
export {
  PROPOSALJSON, TEST
}
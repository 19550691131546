import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import backendApi from './apis/axios-backend'
import 'bootstrap'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

var app=createApp(App);
app.use(store)
    .use(router)
    .mixin({
        methods: {
            backendApi
        },
    });
app.use(ElementPlus)
app.mount("#app");



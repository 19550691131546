<template>
<div class="loading_block_outer" v-if="loading">
    <Loading />
  </div>
  <div v-else class="landing-page-container">
    <!-- <headerComp /> -->
    <innerheader @clicked-rm-details="captureRmData"/>
    
    <div class="main-content">
      
      <div class="mobile-content">
        <span>Online Discount upto 5% off</span>
        <div class="mobile-heading-text">
          <h1>
            Apply For Personal Loan
            <!-- <span class="amt-per-mnth">&#8377;450/<i>month*</i></span> -->
          </h1>
          <span>Get insured from the comfort of your home</span>
        </div>
      </div>
      <!-- <h5 class="position-absolute">Get Personal in 10 seconds Welcome To HDFC Banks Personal Loan</h5> -->
          <!-- <h5 class="position-absolute"></h5> -->
      <div class="left-content">
        
        <div class="position-relative">
          
          <!-- <img src="~@/assets/images/Layer_2.svg" alt="" /> -->
          <img src="~@/assets/images/family.png" alt="" />

          <!-- <div class="discount-badge">
            <img src="~@/assets/images/yellow-badge.png" alt="" />
            <div class="discount-badge-inner">
              <i
                >online <br />
                discount</i
              >
              <b>upto</b>
              <div class="discount-badge-amount">
                <strong>5</strong>
                <div class="discount-text-inner">
                  <span>%</span>
                  <span class="off-text">off</span>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="right-content">
        <div class="right-content-inner">
          <div class="text-center">
            <!-- <div class="text-light bg-danger mb-3" v-if="!rmData">RM data not available, please login first!</div> -->
            <h1 v-if="currentTabIndex<=3">
              Apply For Personal Loan
              <!-- <span class="amt-per-mnth">&#8377;450/<i>month*</i></span> -->
            </h1>
            <h1 v-if="currentTabIndex==4">
              Loan Details
              <!-- <span class="amt-per-mnth">&#8377;450/<i>month*</i></span> -->
            </h1>
            <br>
            <!-- <span>Get insured from the comfort of your home</span> -->
          </div>
         
          <div class="stepper"  v-if="currentTabIndex===1">
            <ul>
              <li class="active-stepper">1</li>
              <!-- completed step html/css is here -->
              <!-- <li class="completed"><img src="~@/assets/images/checked.png"></li> -->
              <li>2</li>
              <li>3</li>
            </ul>
          </div>
          <div class="stepper" v-if="currentTabIndex===2" >
            <ul>
              <li>1</li>
              <li class="active-stepper">2</li>
              <li>3</li>
            </ul>
          </div>
          <div class="stepper" v-if="currentTabIndex===3" >
            <ul>
              <li>1</li>
              <li >2</li>
              <li class="active-stepper">3</li>
            </ul>
          </div>
          <form action="" novalidate>
            <!-- step 1 starts here -->
            
            <div class="step-1" v-if="currentTabIndex===1" >
              
              <!-- <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input"  id="showPass">
                <label class="custom-control-label" for="showPass"></label>
                </div> -->
               <h4 class="cover-tag">Personal Details</h4>
               <!-- <hr class="hr-tag"> -->
              <div class="d-flex radio-btn-wrap">
                <div class="mr-15">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="tools"
                    id="male"
                    v-model="userData.gender"
                    v-bind:value="'Male'"
                  />
                  <label class="for-checkbox-gender d-flex justify-content-center align-items-center" for="male">
                    <i class=""
                      ><img src="~@/assets/images/male.svg" alt=""
                    /></i>
                    <i class="inactive"
                      ><img src="~@/assets/images/male.svg" alt=""
                    /></i>
                    <span>  Male</span>
                  </label>
                </div>
                <!--apply active class here for active state img-->
                <div class="mr-15">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="tools"
                    id="female"
                    v-model="userData.gender"
                    v-bind:value="'Female'"
                  />
                  <label class="for-checkbox-gender d-flex justify-content-center align-items-center" for="female">
                    <i class=""
                      ><img src="~@/assets/images/female.svg" alt=""
                    /></i>
                    <!-- <i class="inactive"
                      ><img src="~@/assets/images/female-inactive.svg" alt=""
                    /></i> -->
                    <span>  Female</span>
                  </label>
                </div>
                <div class="mr-0">
                  <input
                    class="checkbox-tools"
                    type="radio"
                    name="tools"
                    id="transgender"
                    v-model="userData.gender"
                    v-bind:value="'Other'"
                  />
                  <label class="for-checkbox-gender d-flex justify-content-center align-items-center" for="transgender">
                    <i class=""
                      ><img src="~@/assets/images/transgender.svg" alt=""
                    /></i>
                    <!-- <i class="inactive"
                      ><img
                        src="~@/assets/images/transgender-inactive.svg"
                        alt=""
                    /></i> -->
                   <span> Others</span>
                  </label>
                </div>
                  <p class="error-text" v-if="error.gender">
                    {{ error.gender }}
                  </p>
              </div>
              

              <div class="form-control1 label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="fullname"
                  name="fullname"
                  pattern="[A-Za-z]"
                  v-model="userData.fullname"
                  @change="validate('fullname')"
                 
                />
                <label for="fullname" class="form-item-label">Full Name</label>
                <p class="error-text" v-if="error.fullname">
                  {{ error.fullname }}
                </p>
              </div>
              <div class="d-flex form-control1-wrap">
                <div class="form-control1 label-clicked">
                  <input
                    class="form-text"
                    type="date"
                    id="dob"
                    name="dob"
                    v-on:input="calAge"
                    v-model="userData.dob"
                    @keypress="validateDate($event)"
                  />
                  <label for="dob" class="form-item-label">Date Of Birth</label>
                  <span v-if="userData.age" class="blue-bg-span"
                    >{{ userData.age }} Years</span
                  >
                  <p class="error-text" v-if="error.dob">{{ error.dob }}</p>
                </div>
                <div class="form-control1 label-clicked">
                  <select name="country-code" id="country-code" disabled>
                    <option value="+91">+91</option>
                  </select>
                  <span></span>
                  <input
                    class="form-text"
                    type="text"
                    id="mob-no"
                    name="mob-no"
                    maxlength="10"
                    v-model="userData.mobile"
                    @keypress="isNumber($event)"
                  />
                  <label for="mob-no" class="form-item-label"
                    >Mobile Number</label>
                  <p class="error-text" v-if="error.mobile">
                    {{ error.mobile }}
                  </p>
                </div>
              </div>
              <div class="d-flex form-control1-wrap">
              <div class="form-control1 zip-code-input label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="email"
                  name="email"
                  pattern=""
                  maxlength=""
                  v-model="userData.email"
                  @change="validate('email')"
                  
                />
                <label for="email" class="form-item-label">Email</label>
                <!--<span class="blue-bg-span">Mumbai</span>-->
                <p class="error-text" v-if="error.email">
                  {{ error.email }}
                </p>
              </div>
               <div class="form-control1 zip-code-input label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  pattern="\d*"
                  maxlength="6"
                  v-model="userData.zipcode"
                  v-on:input="callPincode($event)"
                  @keypress="isNumber($event)"
                />
                <label for="zip-code" class="form-item-label">Pin Code</label>
                <!--<span class="blue-bg-span">Mumbai</span>-->
                <p class="error-text" v-if="error.zipcode">
                  {{ error.zipcode }}
                </p>
              </div>
              </div>
              <!-- <div v-if="userData2">
                {{ userData2 }}
              </div> -->
              <!-- <div class="text-center"> -->
              <div class="text-center">
                <button type="button" class="next-btn" v-on:click="nextClick()">
                  Next <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button>
              </div>
            </div>
            <!-- step 1 ends here -->
            <!-- step 2 starts here -->
            
            <div class="step-2" id="step2" v-if="currentTabIndex===2" >
              <h4 class="cover-tag">Professional Details</h4>
               <!-- <hr class="hr-tag"> -->
               <div class="d-flex form-control1-wrap">
              <div class="form-control1 label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="companyName"
                  name="companyName"
                  
                  v-model="userData.companyName"
                  
                />
                <label for="income" class="form-item-label" >
                  Company Name
                </label >
                <p class="error-text" v-if="error.companyName">
                  {{ error.companyName }}
                </p>
                <!-- <span class="blue-bg-span" v-if="userData.inlakhs">&#8377; {{userData.inlakhs}} </span> -->
              </div>
              <div class="form-control1 label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="monthlyIncome"
                  name="monthlyIncome"
                  v-on:input="getInLakhs('monthlyIncome')"
                  v-model="userData.monthlyIncome"
                  @keypress="isNumber($event)"
                />
                <label for="income" class="form-item-label">
                  Monthly Income
                </label >
                <p class="error-text" v-if="error.monthlyIncome">
                  {{ error.monthlyIncome }}
                </p>
                <span class="blue-bg-span">&#8377; {{userData.monthlyIncomeinlakhs}} </span>
              </div>
              </div>
              <div class="d-flex form-control1-wrap">
                <div class="form-control1 label-clicked">
                  <select
                    name="employmenttype"
                    id="employmenttype"
                    v-model="userData.employmenttype"
                  >
                    <option value="Salaried">Salaried</option>
                    <option value="Unemployed">Unemployed</option>
                    <option value="Self-employed">Self-Employed</option>
                  </select>
                  <label for="employee" class="form-item-label"
                    >Employment Type</label
                  >
                  <p class="error-text" v-if="error.employmenttype">
                    {{ error.employmenttype }}
                  </p>
                </div>
                <div class="form-control1 label-clicked">
                  <select
                    name="education"
                    id="education"
                    v-model="userData.education"
                  >
                    <option value="graduate">College graduate & above</option>
                    <option value="12">12th Pass</option>
                    <option value="10">10th Pass & below</option>
                  </select>
                  <label for="mob-no" class="form-item-label">Education</label>
                  <p class="error-text" v-if="error.education">
                    {{ error.education }}
                  </p>
                </div>
              </div>
                <!-- basic details -->
              <div class="d-flex form-control1-wrap">
                <div class="form-control1 label-clicked">
                 <input
                  class="form-text"
                  type="text"
                  id="panNumber"
                  name="panNumber"
                  v-model="userData.panNumber"
                  @change="validate('panNumber')"
                  
                 
                />
                <label for="income" class="form-item-label">
                  PAN Number
                </label >
                <p class="error-text" v-if="error.panNumber">
                  {{ error.panNumber }}
                </p>
                </div>
                <div class="form-control1 label-clicked">
                  <select
                    name="city"
                    id="city"
                    v-model="userData.city"
                  >
                    <option value="PUNE">Pune</option>
                    <option value="MUMBAI">Mumbai</option>
                    <option value="DELHI">Delhi</option>
                    <option value="BENGALURU">Bengaluru</option>
                    <option value="CHENNAI">CHennai</option>
                    <option value="HYDERABAD">Hyderabad</option>
                  </select>
                  <label for="mob-no" class="form-item-label">City</label>
                  <p class="error-text" v-if="error.city">
                    {{ error.city }}
                  </p>
                </div>
              </div>
              
              <div class="d-flex form-control1-wrap">
                <div class="form-control1 label-clicked">
                  <select
                    name="residenceType"
                    id="residenceType"
                    v-model="userData.residenceType"
                  >
                    <option value="Owned by Self">Owned by Self</option>
                    <option value="Owned by Parent">Owned by Parent</option>
                    <option value="Rented with Family">Rented with Family</option>
                  </select>
                  <label for="mob-no" class="form-item-label">Residence Type</label>
                  <p class="error-text" v-if="error.residenceType">
                    {{ error.residenceType }}
                  </p>
                </div>
                <div class="form-control1 label-clicked">
                 <input
                  class="form-text"
                  type="text"
                  id="workExp"
                  name="workExp"
                  maxlength="2"
                  v-model="userData.workExp"
                  @keypress="isNumber($event)"
                />
                <label for="income" class="form-item-label">
                  Work Experience
                </label >
                <p class="error-text" v-if="error.workExp">
                  {{ error.workExp }}
                </p>
                <span class="blue-bg-span">{{userData.workExp}} Year </span>

                </div>
                
              </div>
              
              <!-- <div v-if="userData3">
                {{ userData3 }}
              </div> -->
              <div class="text-center">
                <button type="button" class="back-btn" v-on:click="getback">
                  <i><img src="~@/assets/images/back-btn.png" /></i>Back
                </button>
                <!-- <button type="submit" class="next-btn">
                  Get Quote <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button> -->
                <button type="button" v-on:click="nextClick()" class="next-btn">
                  Next <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button>

              </div>
           <!-- 
              <div class="get-updates">
                <div class="get-updates-text">
                  <img src="~@/assets/images/whatsapp.png" />
                  Get updates on WhatsApp
                </div>
                <label class="toggleSwitch nolabel" onclick="">
                  <input type="checkbox" checked v-model="userData.whatsapp" />
                  <span> </span>
                  <a></a>
                </label>
              </div> -->
            </div>

            <!-- Start 3rd step -->
            
             <div class="step-2" id="step2" v-if="currentTabIndex===3" >
              <h4 class="cover-tag">Loan Details</h4>
               <!-- <hr class="hr-tag"> -->
               <div class="d-flex form-control1-wrap">
              <div class="form-control1 label-clicked">
                <input
                  class="form-text"
                  type="text"
                  id="loanAmmount"
                  name="loanAmmount"
                  v-on:input="getInLakhs('loanAmmount')"
                  v-model="userData.loanAmmount"
                  
                  @keypress="isNumber($event)"
                  
                />
                <label for="income" class="form-item-label">
                  Loan Amount
                </label >
                <p class="error-text" v-if="error.loanAmmount">
                  {{ error.loanAmmount }}
                </p>
                <span class="blue-bg-span">&#8377; {{userData.loanAmmountinlakhs}} </span>
              </div>

              <div class="form-control1 label-clicked">
                 <input
                  class="form-text"
                  type="number"
                  id="tenure"
                  name="tenure"
                  
                  v-model="userData.tenure"
                  @keypress="isNumber($event)"
                />
                <label for="income" class="form-item-label">
                  Tenure
                </label >
                <p class="error-text" v-if="error.tenure">
                  {{ error.tenure }}
                </p>
             <span class="blue-bg-span">{{userData.tenure}} Year </span>

                </div>
                </div>

              <div class="d-flex form-control1-wrap">
                <div class="radio-question">
                <span class="radio-label">Have You Taken Loan In Past?</span>
                <div>
                  <div class="mr-15">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="pastLoan"
                      id="yes"
                      v-model="userData.pastLoan"
                      v-bind:value="'Yes'"
                    />
                    <label class="for-checkbox-gender d-flex justify-content-center align-items-center" for="yes">
                      <!-- <i class="">
                        <img src="~@/assets/images/smoking.svg" alt="" />
                      </i> -->
                      Yes
                    </label>
                  </div>
                  <div class="mr-15">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="pastLoan"
                      id="no"
                      v-model="userData.pastLoan"
                      v-bind:value="'No'"
                    />
                    <label class="for-checkbox-gender d-flex justify-content-center align-items-center" for="no">
                      <!-- <i class="">
                        <img src="~@/assets/images/no-smoking.png" alt=""/>
                      </i> -->
                      No
                    </label>
                  </div>
                  <p class="error-text" v-if="error.pastLoan">
                  {{ error.pastLoan }}
                  </p>
                </div>
             </div>

                <div class="form-control1 label-clicked control-emi">
                 <input
                  class="form-text"
                  type="text"
                  id="emi"
                  name="emi"
                  v-on:input="getInLakhs('currentEMI')"
                  v-model="userData.emi"
                  @keypress="isNumber($event)"
                />
                <label for="income" class="form-item-label">
                  Current EMI
                </label >
                <p class="error-text" v-if="error.emi">
                  {{ error.emi }}
                </p>
                <span class="blue-bg-span">&#8377; {{userData.currentEMIinlakhs}} </span>

                </div>

                
                
              </div>
              
              

              <div class="d-flex form-control1-wrap">
                 

             <div class="radio-question">
                <h4>Would You like to add loan protect?</h4>
                <div>
                  <div class="mr-15">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="loanProtect"
                      id="Y"
                      v-model="userData.loanProtect"
                      v-bind:value="'Y'"
                      @change="getLoanProtect($event)"
                    />
                    <label class="for-checkbox-gender d-flex justify-content-center align-items-center" for="Y">
                      <i class="">
                        <!-- <img src="~@/assets/images/smoking.png" alt=""/> -->
                        <!-- <img src="~@/assets/images/smoking.svg" alt="" /> -->
                      </i>
                      Yes
                    </label>
                  </div>
                  <div class="mr-15">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="loanProtect"
                      id="N"
                      v-model="userData.loanProtect"
                      v-bind:value="'N'"
                      @change="getLoanProtect($event)"
                    />
                    <label class="for-checkbox-gender d-flex justify-content-center align-items-center" for="N">
                      <i class="">
                        <!-- <img src="~@/assets/images/no-smoking.png" alt=""/> -->
                      </i>
                      No
                    </label>
                  </div>
                  <!-- <p class="error-text" v-if="error.smoke">
                  {{ error.smoke }}
                  </p> -->
                </div>
             </div>

             <div v-if="userData.loanProtect=='Y'" class="radio-question">
                <h4>Do You Smoke Or Chew Tobacco?</h4>
                <div>
                  <div class="mr-15">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="smoke"
                      id="1"
                      v-model="userData.smoke"
                      v-bind:value="'1'"
                    />
                    <label class="for-checkbox-gender d-flex justify-content-center align-items-center" for="1">
                      <i class="">
                        <!-- <img src="~@/assets/images/smoking.png" alt=""/> -->
                        <img src="~@/assets/images/smoking.svg" alt="" />
                      </i>
                      Yes
                    </label>
                  </div>
                  <div class="mr-15">
                    <input
                      class="checkbox-tools"
                      type="radio"
                      name="smoke"
                      id="0"
                      v-model="userData.smoke"
                      v-bind:value="'0'"
                    />
                    <label class="for-checkbox-gender d-flex justify-content-center align-items-center" for="0">
                      <i class="">
                        <img src="~@/assets/images/no-smoking.png" alt=""/>
                      </i>
                      No
                    </label>
                  </div>
                  <p class="error-text" v-if="error.smoke">
                  {{ error.smoke }}
                  </p>
                </div>
             </div>
              </div>

              <!-- <div class="d-flex form-control1-wrap">

              
              

              </div> -->
                
             

              
              <!-- <div v-if="userData3">
                {{ userData3 }}
              </div> -->
              <div class="text-center">
                <button type="button" class="back-btn" v-on:click="getback">
                  <i><img src="~@/assets/images/back-btn.png" /></i>Back
                </button>
                <!-- <button type="submit" class="next-btn">
                  Get Quote <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button> -->
                <button type="button" v-on:click="nextClick()" class="next-btn">
                  Proceed <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button>

              </div>

              
            </div>


            <!-- step 4 -->
            <div class="step-2" id="step2" v-if="currentTabIndex===4" >
              

              <div class="d-flex form-control2-wrap">
                <div class="form-control2 label-clicked">
                   <label for="income" class="form-item-label">
                  Loan Amount
                </label >
                
                 <input
                 :disabled="true"
                  class="form-text"
                  type="text"
                  id="loanAmmount"
                  name="loanAmmount"
                  
                  
                  v-model="userData.loanAmmount"/>
                  <span class="blue-bg-span1">&#8377; {{userData.loanAmmountinlakhs}} </span>
                  </div>

                <div class="form-control2 label-clicked">
                  <label for="income" class="form-item-label">
                  Loan Term
                </label >
                 <input
                  class="form-text"
                  type="text"
                  id="tenure"
                  name="tenure"
                  :disabled="true"
                  v-model="userData.tenure"
                  
                />
                  <span class="blue-bg-span1">{{userData.tenure}} Year </span>

                
        
                </div>
                 <div class="form-control2 form-width label-clicked">
                  <label for="income" class="form-item-label">
                  ROI 
                </label >
                 <input
                  class="form-text"
                  type="text"
                  id="roi"
                  name="roi"
                  :disabled="true"
                  v-model="userData.roi"
                />
                
        
                </div>
                              

              </div>
                <!-- <h4 class="line"></h4> -->
               <h4 class="cover-tag">Cover Details</h4>
             
               
            
              <div class="d-flex form-control2-wrap">
                 <div class="checkbox-control">
               <label class="control control-checkbox">
                  <input
                    type="checkbox"
                    :checked=userData.checkbox1
                    @change="checkbox1($event)"
                  />
                  <div class="control-indicator"></div>
                </label>
                  <!-- <el-checkbox  :fill= "danger" class="form-text" label="        " @change="checkbox1($event)"    size="large" border /> -->
                  </div>
                <div class="form-control2 label-clicked">
                  
                  <label for="income" class="form-item-label">
                  Life Cover
                </label >
                 <input
                  class="form-text"
                  type="text"
                  id="lifeCover"
                  name="lifeCover"
                  :disabled="true"
                  v-model="userData.loanAmmount"
                  v-on:input="getInLakhs('loanAmmount')"
                

                />
                  <span class="blue-bg-span1">&#8377; {{userData.loanAmmountinlakhs}} </span>


                </div>
                <div class="form-control2 label-clicked">
                  <label for="income" class="form-item-label">
                   Single Premium
                </label >
                 <input
                  class="form-text"
                  type="text"
                  id="singlePremium"
                  name="singlePremium"
                  :disabled="true"
                  v-model="userData.singlePremium"
                />
                <span class="blue-bg-span1">&#8377; {{userData.singlePremiuminlakhs}} </span>

        
                </div>
                <!-- <div class="form-control2 form-width label-clicked"> -->
                  
                
                
        
                <!-- </div> -->
              </div>

              <div class="d-flex form-control2-wrap">
                 
                 <div class="checkbox-control">
                  <label class="control control-checkbox">
                  <input
                    type="checkbox"
                       :checked=userData.checkbox2
                    @change="checkbox2($event)"
                    
                  />
              
                  <div class="control-indicator"></div>
                </label>
                <!-- <el-checkbox  class="form-text" label="        " @change="checkbox2($event)"  size="large" border /> -->
               </div>
                <div class="form-control2 label-clicked">
                  <label for="income" class="form-item-label">
                 Health Cover
                </label >
                 <input
                  class="form-text"
                  type="text"
                  id="healthCover"
                  name="healthCover"
                  :disabled="true"
                  v-model="userData.healthCover"
                />
              <span class="blue-bg-span1">&#8377; {{userData.healthCoverinlakhs}} </span>


                </div>
                <div class="form-control2 label-clicked single-premium-margin">
                  <label for="income" class="form-item-label">
                  
                </label >
                 <input
                  class="form-text"
                  type="text"
                  id="singlePremium"
                  name="singlePremium"
                  :disabled="true"
                  v-model="userData.premiumHealth"
                />
                
               <span class="blue-bg-span1">&#8377; {{userData.premiumHealthinlakhs}} </span>

                </div>
                
              </div>

              <div class="d-flex form-control2-wrap">
                <div class="checkbox-control">
                 <label class="control control-checkbox">
                  <input
                    type="checkbox"
                       :checked=userData.checkbox3
                    @change="checkbox3($event)"
                  />
                  <div class="control-indicator"></div>
                </label>
                <!-- <el-checkbox  class="form-text" label="        " @change="checkbox3($event)"  size="large" border /> -->
                </div>
                <div class="form-control2 label-clicked">
                   <label for="income" class="form-item-label">
                 Accidential Cover
                </label >
                 <input
                  class="form-text"
                  type="text"
                  id="accidentialCover"
                  name="accidentialCover"
                  :disabled="true"
                  v-model="userData.accidentialCover"
                />
                <span class="blue-bg-span1">&#8377; {{userData.loanAmmountinlakhs}} </span>

                   
                </div>
                <div class="form-control2 label-clicked single-premium-margin">
                  <label for="income" class="form-item-label">
                  
                </label >
                 <input
                  class="form-text"
                  type="text"
                  id="singlePremium"
                  name="singlePremium"
                  :disabled="true"
                  v-model="userData.premiumAccidential"
                />
              <span class="blue-bg-span1">&#8377; {{userData.premiumAccidentialinlakhs}} </span>


        
                </div>
                                

              </div>
                  <!-- <h4 class="line"></h4> -->
               <h4 class="cover-tag">Net Loan Amount to be Disbursed</h4>
               
               <!-- <div class="d-flex form-control-total-wrap">
                <span class="loan-premium">Loan Amount + Premium </span>
                 <div class="form-control2 label-clicked">
                   <label for="income" class="form-item-label">
                 Accidential Cover
                </label >
                 <input
                  class="form-text"
                  type="text"
                  id="accidentialCover"
                  name="accidentialCover"
                  :disabled="true"
                  v-model="userData.accidentialCover"
                />
                <span class="blue-bg-span1">&#8377; {{userData.loanAmmountinlakhs}} </span>

                   
                </div>
              <div class="form-control2 label-clicked">
                  
                 <input
                  class="form-text"
                  type="text"
                  id="totalAmmount"
                  name="totalAmmount"
                  :disabled="true"
                  v-model="userData.totalAmmount"
                />
              <span class="blue-bg-span1">&#8377; {{userData.totalAmountinlakhs}} </span>

        
                </div>
                </div> -->

                 <div class="d-flex form-control2-wrap">
                
                                <span class="loan-premium">Loan Amount + Premium </span>
                   
                <div class="form-control2 label-clicked">
                  
                 <input
                  class="form-text"
                  type="text"
                  id="totalAmmount"
                  name="totalAmmount"
                  :disabled="true"
                  v-model="userData.totalAmmount"
                />
              <span class="blue-bg-span1">&#8377; {{userData.totalAmountinlakhs}} </span>

        
                </div>
                <!-- <div class="form-control2 label-clicked ">
                  <label for="income" class="form-item-label">
                  
                </label >
                 <input
                  class="form-text"
                  type="text"
                  id="singlePremium"
                  name="singlePremium"
                  :disabled="true"
                  v-model="userData.premiumAccidential"
                />
              <span class="blue-bg-span1">&#8377; {{userData.premiumAccidentialinlakhs}} </span>


        
                </div> -->
                                

              </div>


             
              
            
              <div class="text-center">
                <button type="button" class="back-btn" v-on:click="getback">
                  <i><img src="~@/assets/images/back-btn.png" /></i>Back
                </button>
                <!-- <button type="submit" class="next-btn">
                  Get Quote <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button> -->
                <button type="button" v-on:click="callLoanDetail" class="next-btn">
                  Disbursed <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button>

              </div>

              
            
          </div>
          </form>
        </div>
        <footerComp />
      </div>
    </div>
  </div>

  <div class="version-dtls">
    <span>Version 0.0.0.1</span>
  </div>
</template>
<script>

import innerheader from "@/components/innerheader/innerheader.vue";
import footerComp from "@/components/footer/footer-comp.vue";
import Loading from "@/components/loading.vue";
//import Multiselect from 'vue-multiselect'
import Multiselect from '@vueform/multiselect'
import { ElCheckbox } from 'element-plus'
// register globally
export default {
  name: "personal-lone",
  props: {
    msg: String,
  },
  components: {
    ElCheckbox,
    footerComp,
    //headerComp,
    innerheader,
    Multiselect,
    Loading
  },
  mounted() {
    if(!sessionStorage.getItem("current_user"))
   {
    console.log("fhdfgdhfdkkkkkkkkkkkk")
    this.$router.push('/personal-loan-login')
   }
    if(JSON.parse(sessionStorage.getItem("currentTabIndex")))
    {
      this.currentTabIndex=JSON.parse(sessionStorage.getItem("currentTabIndex"))
    }
    if(JSON.parse(sessionStorage.getItem("UserData")))
    {
      this.userData=JSON.parse(sessionStorage.getItem("UserData"))
    }
    this.getInLakhs("healthCover")
   

  },
  data() {
    return {
      loading:false,
      currentTabIndex:1,
      isHidden: true,
      error: [],
      flag:false,
      userData2: null,
      personalAdons:[],
      userData: {
        gender: null,
        fullname: null,
        dob: null,
        age: null,
        mobile: null,
        zipcode: null,
        annualincome:null,
        companyName:null,
        employmenttype: null,
        education: null,
        panNumber:null,
        city:null,
        residenceType:null,
        workExp:null,
        
        loanAmmount:null,
        emi:null,
        tenure:null,
        pastLoan:null,
        monthlyIncome: null,
        inlakhs: null,
        loanProtect:false,
        smoke: null,
        whatsapp: false,
        sumAssured:null,

        lifeCover:null,
        singlePremium:null,
        premiumHealth:null,
        premiumAccidential:null,
        healthCover:"2,00,000",
        accidentialCover:"3,00,000",
        roi: 10 + "%",
        totalAmmount:0,

        checkbox1:false,
        checkbox2:false,
        checkbox3:false,

      loanAmmountinlakhs:null,
      currentEMIinlakhs:null,
      monthlyIncomeinlakhs:null,
      
      healthCoverinlakhs:null,
      accidentialCoverinlakhs:null,
      singlePremiuminlakhs:null,
      premiumHealthinlakhs:null,
      premiumAccidentialinlakhs:null,
      totalAmountinlakhs:null,

        
      },
      loanCheck:null,
      
      rmData: "",
      terms: "",
      selected: [],
      value: "",
      //options: ['Laravel', 'Laravel 8', 'Vue JS', 'codeplaners.com']
      options: [
        { name: "Vue.js", language: "JavaScript" },
        { name: "Adonis", language: "JavaScript" },
        { name: "Rails", language: "Ruby" },
        { name: "Sinatra", language: "Ruby" },
        { name: "Laravel", language: "PHP" },
        { name: "Phoenix", language: "Elixir" }
      ],
    singleQuoteResponse:null,
    singleQuoteData: {
        "typeOfQuote": "Single Quote",
        "quoteCategory": "Sum Assured",
        "journey_id":"",
        "alternateFreqRequired": "Yes",
        "outOfBoundConfig": "Yes",
        "distributor": {
            "distributorID": "ARKAFIN",
            "agentID": "8925",
            "salesChannel": "Others",
            "channelType":"B2B"
        },
        "personalInformation": {
            "individualDetails": [
                {
                    "memberType": "Life Assured",
                    "memberSequenceNumber": 1,
                    "firstName": "Mrunali",
                    "middleName": "",
                    "lastName": "",
                    "gender": "Male",
                    "dateOfBirth": "2000-07-21",
                    "maritalStatus": "",
                    "qualification": "",
                    "occupation": "",
                    "mobileNumber": 8767745801,
                    "annualIncome": 10000000,
                    "email": "",
                    "tobacco": "No",
                    "relationWithFirstLifeAssured": "Self",
                    "zipCode": "412201",
                    "state": "",
                    "city": "",
                    "quoteAmount": 2000000
                }
            ]
        },
        "product": {
            "productType": "GrpTerm",
            "insuranceAndProducts": [
                {
                    "insuranceCompanyCode": "ICICI",
                    "productCode": [
                        "GP1"
                    ]
                }
            ],
            "premiumPaymentTerm": 1,
            "policyTerm": 5,
            "policyTermUnit": "",
            "premiumPaymentOption": "1",
            "premiumPaymentFrequency": "S",
            "planOption": {
                "OptionSelected": ""
            },
            "loanSection": {
                "loanAmount": 3000000,
                "loanTenure": 10,
                "rateCode": "PFL00LBL"
            },
            "coverOption": {
                "OptionSelected": "2"
            },
            "DBPoption": {
                "OptionSelected": "4"
            },
            "AddOnSection": {
                "returnOfPremium": {
                    "returnOfPremiumPercentage": 0
                }
            },
            "riderInformation": {}
        }
    },

    };
  },
  methods: {
    // Validation Rules
    // Numbers only
  formatRupee(){
      // console.log("Check",this.userData.loanAmmount,typeof(this.userData.loanAmmount))
      // if(this.userData.loanAmmount)
      // this.userData.loanAmmount = this.userData.loanAmmount.replace(/\D/g, "").replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")
      // console.log("Checking",this.userData.annualincome)
    },
  formatAnnualIncome(name){
      
      if(name=="loanAmmount")
      this.userData.loanAmmount = this.userData.loanAmmount.replace(/\D/g, "").replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")
      else if(name=="currentEMI")
      this.userData.emi = this.userData.emi.replace(/\D/g, "").replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")
      else if(name=="monthlyIncome")
      this.userData.monthlyIncome = this.userData.monthlyIncome.replace(/\D/g, "").replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")
      else if(name=="healthCover")
      this.userData.healthCover = this.userData.healthCover.replace(/\D/g, "").replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")

    },
        validate(name)
    {
      const NAME = /^([a-zA-Z/\\.\s]{1,255})$/;
      const EMAIL = /^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/
      const PAN = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if(name=="fullname")
      {
        
        let checkName=this.userData.fullname.match(NAME)
        if(checkName==null)
        {
           this.error["fullname"] = "Please enter valid name"
        }
        else{
           this.error["fullname"]=""
        }
      }
       else if(name=="email")
      {
        
        let checkEmail=this.userData.email.match(EMAIL)
        if(checkEmail==null)
        {
           this.error["email"] = "Please enter valid email id"
        }
        else{
           this.error["email"]=""
        }
      }
      else if(name=="panNumber")
      {
        let checkPan=this.userData.panNumber.match(PAN)
        if(checkPan==null)
        {
           this.error["panNumber"] = "Please enter valid Pan number"
        }
        else{
           this.error["panNumber"]=""
        }
      }
    },
    getINRCurrency(num)
    {
      console.log("typenum",typeof(num),num)
     let text = num.toLocaleString("en-IN", {maximumFractionDigits: 0, currency:"INR"});
     console.log("text",text)
     return text
    },
    getInitialJourney()
    {
      this.loading=true
        let reqData=  {
    "externalReferenceId": "987612345",
    "regionId": "Mumbai",
    "roleId": "Emp",
    "userid": "A009",
    "userType": "BQP",
    "branchId": "8541",
    "emailId": "test@1sb.com"
};
      let headers = {};
      let getParam=[]
      
      this.backendApi("getJourneyId", reqData, getParam, headers)
        .then((response) => {
          
          
         
          if(response == 'error') {
            
            console.log('error block called : get policy'); 
          } 
          else if(response.status=="error")
          {
            console.log("error",response)

          }
          else if(response && response.data.data.journeyId) {
             this.getQuote(response.data.data.journeyId)
          }
          
        })
        .catch((error) => {
          console.log('error msg block : get policy', error);
          
        });
    },
    getQuote(journeyId) {
      let monthlyInc = this.userData.monthlyIncome.replaceAll(',', '')
      let loan = this.userData.loanAmmount.replaceAll(',', '')
      this.singleQuoteData.journey_id = journeyId,
        this.singleQuoteData.personalInformation.individualDetails[0].annualIncome = parseInt(monthlyInc) * 12
      this.singleQuoteData.personalInformation.individualDetails[0].city = this.userData.city
      this.singleQuoteData.personalInformation.individualDetails[0].dateOfBirth = this.userData.dob
      this.singleQuoteData.personalInformation.individualDetails[0].email = this.userData.emailId
      this.singleQuoteData.personalInformation.individualDetails[0].firstName = this.userData.fullname
      this.singleQuoteData.personalInformation.individualDetails[0].gender = this.userData.gender
      this.singleQuoteData.personalInformation.individualDetails[0].mobileNumber = parseInt(this.userData.mobile)
      this.singleQuoteData.personalInformation.individualDetails[0].tobacco = this.userData.smoke == '1' ? "Yes" : "No"
      this.singleQuoteData.personalInformation.individualDetails[0].zipCode = this.userData.zipcode
      this.singleQuoteData.product.loanSection.loanAmount = parseInt(loan)
      this.singleQuoteData.product.loanSection.loanTenure = this.userData.tenure
      this.singleQuoteData.personalInformation.individualDetails[0].quoteAmount = parseInt(loan)
      let reqData = this.singleQuoteData;
      // let getParam=[journeyId]
      let headers = {};

      this.backendApi("getQuoteArka", reqData, [], headers)
        .then((response) => {
          sessionStorage.setItem("UserData", JSON.stringify(this.userData))
          if (response == 'error') {

            console.log('error block called : get policy');
          }
          else if (response.data.errors && response.data.errors && response.data.errors.length > 0) {
            // let msg=['We are facing some issue while fetching quote data']
            this.loading = false
            let msg = []
            for (let i = 0; i < response.data.errors.errors.length; i++) {
              msg.push(response.data.errors.errors[i]["errorMessage"])
            }

            this.$router.push({ path: '/alert', query: { backUrl: 'personal-loan', msg: msg } });

          }
          else if (response.status == "error") {
            console.log("error", response)


          }
          else if (response) {
            this.getQuotePoll(response.data.reqId);
          }
        })
        .catch((error) => {
          console.log('error msg block : get policy', error);

        });
    },
     currency(amt) {
      return new Intl.NumberFormat().format(amt)
    },
    async getQuotePoll(reqId) {
      const response = await this.backendApi("getQuoteArkaPoll", {}, reqId, {}, "get");
      if(!response.data.data.isPollComplete){
        // setTimeout(function(){
          this.getQuotePoll(reqId);
        // }, 1000);
        
      }
      else{
        this.singleQuoteResponse = response.data.data.quote[0].individualDetails[0]
      this.userData.singlePremium = this.getINRCurrency(this.singleQuoteResponse.premiumDetails[0].totalPremiumValue)
      this.userData.premiumHealth = this.getINRCurrency(Math.round((this.singleQuoteResponse.premiumDetails[0].totalPremiumValue) / 2))
      this.userData.premiumAccidential = this.getINRCurrency(Math.round((this.singleQuoteResponse.premiumDetails[0].totalPremiumValue) / 3))
      this.userData.sumAssured = this.singleQuoteResponse.sumAssured
      this.currentTabIndex += 1
      this.loading = false
      // this.userData.singlePremium = this.userData.singlePremium.replace(/\D/g, "").replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")
      console.log("single premium", this.userData.singlePremium)
      this.getInLakhs("singlePremium")
      this.getInLakhs("premiumHealth")
      this.getInLakhs("premiumAccidential")
      if (this.currentTabIndex == 4) {
        sessionStorage.setItem("currentTabIndex", JSON.stringify(this.currentTabIndex))
        this.userData.totalAmmount = this.userData.loanAmmount
        this.userData.accidentialCover = this.userData.loanAmmount
        this.userData.checkbox1 = false
        this.userData.checkbox2 = false
        this.userData.checkbox3 = false
        this.getInLakhs("totalAmount")
        sessionStorage.setItem("UserData", JSON.stringify(this.userData))
      }

      }
      
    },
    getLoanProtect(event){
      if(event.target.value=="Y")
      {
        this.userData.smoke=null
      }
      
    },
    checkBoxForSmoke(event){
      this.userData.loanProtect=event
    },
    checkbox1(event)
    {
      
      if(event.target.checked)
      {
        console.log("total",parseInt(this.userData.totalAmmount.replaceAll(',','')),parseInt(this.userData.singlePremium.replaceAll(',','')),this.getINRCurrency(parseInt(this.userData.totalAmmount.replaceAll(',','')) + parseInt(this.userData.singlePremium.replaceAll(',',''))))
       this.userData.lifeCover=this.userData.loanAmmount
       this.userData.totalAmmount= this.getINRCurrency(parseInt(this.userData.totalAmmount.replaceAll(',','')) + parseInt(this.userData.singlePremium.replaceAll(',','')))
       this.userData.checkbox1=true
       
      }
      else
      {
      this.userData.lifeCover=null
      this.userData.totalAmmount=this.getINRCurrency(parseInt(this.userData.totalAmmount.replaceAll(',',''))-parseInt(this.userData.singlePremium.replaceAll(',','')))
      this.userData.checkbox1=false
      }
      this.getInLakhs("totalAmount")
    },
    checkbox2(event)
    {
      if(event.target.checked)
      {
      // this.userData.healthCover=20000
      this.userData.totalAmmount= this.getINRCurrency(parseInt(this.userData.totalAmmount.replaceAll(',','')) +parseInt(this.userData.premiumHealth.replaceAll(',','')))
      this.userData.checkbox2=true
      }
      else
      {

       this.userData.totalAmmount= this.getINRCurrency(parseInt(this.userData.totalAmmount.replaceAll(',','')) - parseInt(this.userData.premiumHealth.replaceAll(',','')))
      // this.userData.healthCover=null
      this.userData.checkbox2=false
      }
      this.getInLakhs("totalAmount")
    },
    checkbox3(event)
    {
      if(event.target.checked)
      {
      // this.userData.accidentialCover=30000
      this.userData.checkbox3=true
      this.userData.totalAmmount= this.getINRCurrency(parseInt(this.userData.totalAmmount.replaceAll(',','')) +parseInt(this.userData.premiumAccidential.replaceAll(',','')))
      }
      else
      {
      // this.userData.accidentialCover=null
      this.userData.checkbox3=false
      this.userData.totalAmmount= this.getINRCurrency(parseInt(this.userData.totalAmmount.replaceAll(',','')) -parseInt(this.userData.premiumAccidential.replaceAll(',','')))
      }
      this.getInLakhs("totalAmount")

    },
     callPincode(evt) {
      let val = evt.target.value;
    
      if (val.length == 6) {

        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        let reqData = [val];
        let getParam=[]
        //this.loading = true;
        
        this.backendApi("getPincodeInfo", reqData, getParam, headers, "get")
          .then((response) => {
            //this.loading = false;
            if (response == "error") {
              //this.apiError = true;
              console.log("pincode api error: block");
            } else if (response && response.data) {
              //console.log('pincode response: ', response.data.data);
              let v = response.data.data[0];
              this.userData.city = response.data.data[0].city;


              //
            }
          })
          .catch((error) => {
            console.log("error msg block getting pincode info", error);
            //this.loading = false;
            //this.apiError = true;
          });
      } else {
      }
    },
    nextClick()
    {
      console.log("userDataaaaa",this.userData)
      this.error=[]
      if(this.currentTabIndex==1)
      {
        if(this.userData.gender && this.userData.fullname && this.userData.dob && this.userData.mobile && this.userData.email && this.userData.zipcode )
        {
          this.currentTabIndex+=1
          sessionStorage.setItem("UserData",JSON.stringify(this.userData))
      sessionStorage.setItem("currentTabIndex",JSON.stringify(this.currentTabIndex))
        }
        else{
        
            if (!this.userData.gender) {
          this.error["gender"] = "Please Select Your Gender";
        }
        if (!this.userData.fullname) {
          this.error["fullname"] = "Please Enter Your Full Name";
        }
        if (!this.userData.dob) {
          this.error["dob"] = "Please Enter Your Date of Birth";
        }
        if (!this.userData.mobile) {
          this.error["mobile"] = "Please Enter Your Mobile Number";
        }
        if (!this.userData.zipcode) {
          this.error["zipcode"] = "Please Enter Pincode";
        }
        if (!this.userData.email) {
          this.error["email"] = "Please Enter Your Email";
        }
        console.log(this.error);
        }
      }
      else if(this.currentTabIndex==2)
      {
        if(this.userData.companyName && this.userData.employmenttype && this.userData.education && this.userData.panNumber && this.userData.city && this.userData.residenceType && this.userData.workExp)
        {
          this.currentTabIndex+=1
          sessionStorage.setItem("UserData",JSON.stringify(this.userData))
      sessionStorage.setItem("currentTabIndex",JSON.stringify(this.currentTabIndex))
        }
        else
        { 
          if (!this.userData.companyName) {
          this.error["companyName"] = "Please Select Your company name";
        }
        if (!this.userData.employmenttype) {
          this.error["employmenttype"] = "Please Select Employment Type";
        }
        if (!this.userData.education) {
          this.error["education"] = "Please Select Your Eduction";
        }
        if (!this.userData.panNumber) {
          this.error["panNumber"] = "Please Enter Your Pan Number";
        }
        if (!this.userData.city) {
          this.error["city"] = "Please Enter City";
        }
        if (!this.userData.workExp) {
          this.error["workExp"] = "Please Enter Your Work Experience";
        }
        if (!this.userData.residenceType) {
          this.error["residenceType"] = "Please Enter Your Residence Type";
        }
        console.log(this.error);
          
        }
      }
      else if(this.currentTabIndex==3)
      {
        if(this.userData.loanAmmount && this.userData.emi && this.userData.tenure && this.userData.monthlyIncome && this.userData.pastLoan)
        {
          this.getInitialJourney()
          sessionStorage.setItem("UserData",JSON.stringify(this.userData))
      sessionStorage.setItem("currentTabIndex",JSON.stringify(this.currentTabIndex))
        }
        else
        { 
          if (!this.userData.loanAmmount) {
          this.error["loanAmmount"] = "Please Select Your company name";
        }
        if (!this.userData.emi) {
          this.error["emi"] = "Please Enter Total EMI Pay Currently";
        }
        if (!this.userData.tenure) {
          this.error["tenure"] = "Please Enter Tenure";
        }
        if (!this.userData.monthlyIncome) {
          this.error["monthlyIncome"] = "Please Enter Monthly Income";
        }
        if (!this.userData.pastLoan) {
          this.error["pastLoan"] = "Please Select Yes or No";
        }
        
        console.log(this.error);
          
        }
      }

     
      //  if(this.currentTabIndex<4)
      //  {
      //   this.currentTabIndex+=1
      //  }
    },
    callLoanDetail(){
      sessionStorage.setItem("UserData",JSON.stringify(this.userData))
      sessionStorage.setItem("currentTabIndex",JSON.stringify(this.currentTabIndex))
       this.$router.push('/loan-details')
      
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      console.log(charCode);
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode == 46) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    toPaymentPage(){
      // alert(document.getElementById('MyId').value);
      window.location.href = document.getElementById('MyId').value;
    },
    lettersOnly(evt) {
      evt = (evt) ? evt : event;
      var charCode = (evt.charCode) ? evt.charCode : ((evt.keyCode) ? evt.keyCode : ((evt.which) ? evt.which : 0));
      if (charCode > 31 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
        evt.preventDefault();
      }
      return true;
    },
    lettersSpaceOnly(evt) {
      evt = (evt) ? evt : event;
      var charCode = (evt.charCode) ? evt.charCode : ((evt.keyCode) ? evt.keyCode : ((evt.which) ? evt.which : 0));
      if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
        evt.preventDefault();
      }
      return true;
    },
    
    getData1() {
      
      if (
        this.userData.gender &&
        this.userData.fullname.trim().length > 0 &&
        this.userData.dob &&
        this.userData.zipcode &&
        this.userData.mobile
      ) {
        if(this.userData.fullname.trim().length > 1) {
            if(this.getAge(this.userData.dob) > 0){
            this.error = [];
            if(this.userData.mobile.toString().length == '10'  && this.checkSameString(this.userData.mobile.toString()) == false) {
              this.error = [];
              if(this.userData.zipcode.toString().length == '6' && this.checkSameString(this.userData.zipcode.toString()) == false) {
                this.error = [];
                this.userData.age = this.getAge(this.userData.dob);
                this.isHidden = false;
                console.log("data one called");
                this.userData2 = this.userData;
                console.log(this.userData);
              } else {
                this.error["zipcode"] = "Please Enter Valid Pincode";
              }
            } else {
              this.error["mobile"] = "Please Enter Valid Mobile Number";
            }
          } else {
            this.error["dob"] = "Please Enter Valid Date of Birth";
          }
        } else {
          this.error["fullname"] = "Please Enter Valid Name";
        }
        
        
      } else {
        this.error = [];
        if (!this.userData.gender) {
          this.error["gender"] = "Please Select Your Gender";
        }
        if (!this.userData.fullname.trim().length) {
          this.error["fullname"] = "Please Enter Your Full Name";
        }
        if (!this.userData.dob) {
          this.error["dob"] = "Please Enter Your Date of Birth";
        }
        if (!this.userData.mobile) {
          this.error["mobile"] = "Please Enter Your Mobile Number";
        }
        if (!this.userData.zipcode) {
          this.error["zipcode"] = "Please Enter Pincode";
        }
        console.log(this.error);
      }
    },

    cpatureData() {
      if (
        this.userData.annualincome &&
        this.userData.employmenttype &&
        this.userData.education &&
        this.userData.smoke
      ) {
        if(this.validateAnnualIncome() == true) {
          this.error = [];

          //let userData3 = JSON.stringify(this.userData);
          sessionStorage.setItem('userProfile',JSON.stringify(this.userData));
          sessionStorage.setItem('user_profile',JSON.stringify(this.userData));
          //this.$router.push({path: '/policy-quote', query:{ gender: this.userData.gender, fullname: this.userData.fullname, dob: this.userData.dob, age: this.userData.age, mobile: this.userData.mobile, zipcode: this.userData.zipcode, annualincome: this.userData.annualincome, inlakhs: this.userData.inlakhs, employmenttype: this.userData.employmenttype, education: this.userData.education, smoke: this.userData.smoke, whatsapp: this.userData.whatsapp}})
          //this.$router.push({path: '/policy-quote', query:{ gender: this.userData.gender, fullname: this.userData.fullname, dob: this.userData.dob, age: this.userData.age, mobile: this.userData.mobile, zipcode: this.userData.zipcode, annualincome: this.userData.annualincome, inlakhs: this.userData.inlakhs, employmenttype: this.userData.employmenttype, education: this.userData.education, smoke: this.userData.smoke, whatsapp: this.userData.whatsapp}})
          this.$router.push('/proposal');
          
        }
        
      } else {
        this.error = [];
        if (!this.userData.annualincome) {
          this.error["annualincome"] = "Please Enter Your Annual Income";
        }
        if (!this.userData.employmenttype) {
          this.error["employmenttype"] = "Please Select Your Employment Type";
        }
        if (!this.userData.education) {
          this.error["education"] = "Please Select Your Educational Qualification";
        }
        if (!this.userData.smoke) {
          this.error["smoke"] = "This field is required";
        }
      }
    },

    getback() {
      if(this.currentTabIndex>1 && this.currentTabIndex<=4)
      {
         this.currentTabIndex-=1
        sessionStorage.setItem("currentTabIndex",JSON.stringify(this.currentTabIndex))

      }
      // this.isHidden = true;
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 99) {
        return age;
      }
    },

    calAge() {
      this.userData.age = this.getAge(this.userData.dob);
      this.error["dob"] = null;
    },

    getInLakhs(name) {
      if(name=="loanAmmount")
      {
        // if(typeof(this.userData.loanAmmount)=="string")
        console.log(typeof(this.userData.loanAmmount))
        if(this.userData.loanAmmount)
        {
      let loan = this.userData.loanAmmount.replaceAll(',','')
      console.log("Loan",loan)
      loan=parseInt(loan)
      let anuInc =  loan / 100000;
      if(anuInc < 100) {
        this.userData.loanAmmountinlakhs = (loan / 100000) + ' Lacs';
      } else {
        this.userData.loanAmmountinlakhs = (loan / 10000000) + ' Cr.';
      }
      this.formatAnnualIncome("loanAmmount")
        }
        else{
          this.userData.loanAmmountinlakhs=""
        }
      }
      else if(name=="currentEMI")
      {
        if(this.userData.emi)
        {
        let emi = this.userData.emi.replaceAll(',','')
        emi=parseInt(emi)
        let anuEmi =  emi / 100000;
      if(anuEmi < 100) {
        this.userData.currentEMIinlakhs = (emi / 100000) + ' Lacs';
      } else {
        this.userData.currentEMIinlakhs = (emi / 10000000) + ' Cr.';
      }
      this.formatAnnualIncome("currentEMI")
        }
        else{
          this.userData.currentEMIinlakhs=""
        }
      }
      else if(name=="monthlyIncome")
      {
        if(this.userData.monthlyIncome)
        {
         let monthlyInc = this.userData.monthlyIncome.replaceAll(',','')
        monthlyInc=parseInt(monthlyInc)
         let income =  monthlyInc / 100000;
      if(income < 100) {
        this.userData.monthlyIncomeinlakhs = (monthlyInc / 100000) + ' Lacs';
      } else {
        this.userData.monthlyIncomeinlakhs = (monthlyInc / 10000000) + ' Cr.';
      }

      this.formatAnnualIncome("monthlyIncome")
        }
        else
      {
         this.userData.monthlyIncomeinlakhs=""
      }
      }
      else if(name=="healthCover")
      {
        console.log("health",this.userData.healthCover)
         if(this.userData.healthCover)
        {
          
         let healthCov = this.userData.healthCover.replaceAll(',','')
        healthCov=parseInt(healthCov)
         let health =  healthCov / 100000;
      if(health < 100) {
        this.userData.healthCoverinlakhs = (healthCov / 100000) + ' Lacs';
      } else {
        this.userData.healthCoverinlakhs = (healthCov / 10000000) + ' Cr.';
      }

      // this.formatAnnualIncome("healthCover")
        }
        else
      {
         this.userData.healthCoverinlakhs=""
      }
      }
      else if(name=="singlePremium")
      {
        // console.log("health",this.userData.healthCover)
         if(this.userData.singlePremium)
        {
          
         let singlePre = this.userData.singlePremium.replaceAll(',','')
        singlePre=parseInt(singlePre)
         let premium =  singlePre / 100000;
      if(premium < 100) {
        this.userData.singlePremiuminlakhs = (singlePre / 100000) + ' Lacs';
      } else {
        this.userData.singlePremiuminlakhs = (singlePre / 10000000) + ' Cr.';
      }

      // this.formatAnnualIncome("healthCover")
        }
        else
      {
         this.userData.singlePremiuminlakhs=""
      }
      }
      else if(name=="premiumHealth")
      {
        // console.log("health",this.userData.healthCover)
         if(this.userData.premiumHealth)
        {
          
         let healthPre = this.userData.premiumHealth.replaceAll(',','')
        healthPre=parseInt(healthPre)
         let health =  healthPre / 100000;
      if(health < 100) {
        this.userData.premiumHealthinlakhs = (healthPre / 100000) + ' Lacs';
      } else {
        this.userData.premiumHealthinlakhs = (healthPre / 10000000) + ' Cr.';
      }

      // this.formatAnnualIncome("healthCover")
        }
        else
      {
         this.userData.premiumHealthinlakhs=""
      }
      }
      else if(name=="premiumAccidential")
      {
        // console.log("health",this.userData.healthCover)
         if(this.userData.premiumAccidential)
        {
          
         let accident = this.userData.premiumAccidential.replaceAll(',','')
        accident=parseInt(accident)
         let health =  accident / 100000;
      if(health < 100) {
        this.userData.premiumAccidentialinlakhs = (accident / 100000) + ' Lacs';
      } else {
        this.userData.premiumAccidentialinlakhs = (accident / 10000000) + ' Cr.';
      }

      // this.formatAnnualIncome("healthCover")
        }
        else
      {
         this.userData.premiumAccidentialinlakhs=""
      }
      }
      else if(name=="totalAmount")
      {
        // console.log("health",this.userData.healthCover)
         if(this.userData.totalAmmount)
        {
          
         let totalAmt = this.userData.totalAmmount.replaceAll(',','')
        totalAmt=parseInt(totalAmt)
         let total =  totalAmt / 100000;
      if(total < 100) {
        this.userData.totalAmountinlakhs = (totalAmt / 100000) + ' Lacs';
      } else {
        this.userData.totalAmountinlakhs = (totalAmt / 10000000) + ' Cr.';
      }

      // this.formatAnnualIncome("healthCover")
        }
        else
      {
         this.userData.totalAmountinlakhs=""
      }
      }
    },
    captureRmData(val) {
      this.rmData = val;
    },
    validateAnnualIncome() {

      if(this.userData.annualincome == 0 && this.userData.employmenttype == 'unemployed') {
        return true;
      } else if(this.userData.annualincome > 0 && this.userData.annualincome <= 50000000) {
        return true;
      } else if(this.userData.annualincome > 0 && this.userData.annualincome > 50000000) {
        this.error["annualincome"] = "Annual Income Must be Upto 5 Cr.";
        return false;
      } else if(this.userData.annualincome == 0 && this.userData.employmenttype != 'unemployed') {
        this.error["annualincome"] = "Please Enter Your Annual Income";
        return false;
      } else {
        return false;
      }
    },
    checkSameString(data) {
      let firstChar = data[0];
      let len = data.length;
      let flag = true;
      for(let i = 1; i < len; i++) {
        if(firstChar != data[i]) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    validateDate(event) {
      let val = event.target.value;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "~@/assets/scss/components/index.scss";
@import "~@/assets/scss/fonts.scss";
// @import "~@/assets/scss/components/proposal-page.scss";
// .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
//   background-color:#fa9d17 !important;
//   border-color: #fa9d17 !important;
  
  
// }
.control {
            display: inline-block;
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            font-size: 13px;
            font-family: $font-stack;
            width: auto;
            color: #fa9d17;
            font-weight: $font-weight-n;
            text-align: left;
            input {
                position: absolute;
                z-index: -1;
                opacity: 0;
                &:checked~.control-indicator {
                    border: 1px solid #fa9d17;
                    background: #fa9d17;
                    &:after {
                        display: block;
                        color: #fff;
                    }
                }
            }
            .control-indicator {
                position: absolute;
                top: 2px;
                left: 0;
                height: 22px;
                width: 22px;
                border: 1px solid #ccc;
                background: transparent;
                border-radius: 3px;
                &:after {
                    content: '';
                    position: absolute;
                    display: none;
                    background: url('~@/assets/images/check.png') no-repeat;
                    width: 10px;
                    height: 9px;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 3px;
                    right: 0;
                }
            }
        }
.radio-label
{
  font-size: 14px;
  color: #575757;
  font-family: 'Gotham';
  font-weight: normal;
}

// .control-checkbox {
//             width: 100%;
//             font-weight: $font-weight-medium;
//             color: #000;
//             font-size: 14px;
//             input {
//                 &:checked~.control-indicator {
//                     border: 1px solid $main-bg;
//                     background: $main-bg;
//                     &:after {
//                         display: block;
//                         color: #fff;
//                     }
//                 }
//             }
//             .control-indicator {
//                 left: auto;
//                 right: 0;
//             }
//         }


</style>
<!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->
<template>
<div>
  <div class="inner-page-bg" v-if="selectedPlan">
    <div class="checkout-container">
      <div>
          <router-link to="/policydetails" class="back-to-link">
            <img src="~@/assets/images/back-arrow.png" alt="" />
            Back To Policy Details
          </router-link>
        </div>
      <div class="policyname d-flex justify-content-between align-items-center">
        <div class="policyname-text d-flex align-items-center">
          <router-link to="/policydetails" class="back-arrow">
            <img src="~@/assets/images/left-arrow-grey.png" alt="">
          </router-link>
          <span class="mr-4">
            <!-- <img src="~@/assets/images/HDFC_Life.png"> -->
            <img
              :src="
                getInsurerImgs(
                  selectedPlan['insuranceAndProducts']['insuranceCompanyCode']
                )
              "
              alt=""
            />
          </span>
          <h6>{{ selectedPlan['insuranceAndProducts']['productName'] }}</h6>
        </div>
        <div>
          <a :href="selectedPlan.other.biUrl" target="_blank">
            <button class="bi-btn next-btn"> Business Illustration </button>
          </a>
        </div>
      </div>
      <div class="chckout-table rounded position-relative">
        <h5>Please Review Below Details Before Proceeding Ahead.</h5>
        <span class="subtext d-block text-center">These Cannot Be Changed At A Later Stage</span>
        <div class="accordion" id="checkoutaccordion">
          <div class="card z-depth-0 bordered">
            <div class="card-header" id="headingOne">
              <div class="d-flex justify-content-between align-items-center">
                <h6>Personal Info</h6>
                <div class="d-flex cursor-pointer" data-toggle="modal" data-target="#editprofile">
                  <img src="~@/assets/images/edit-profile.png">
                  Edit
                </div>
              </div>
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  Collapsible Group Item #1
                </button>
              </h5>
            </div>
            <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
              data-parent="#checkoutaccordion">
              <div class="card-body">
                <table>
                  <tr>
                    <td>
                      <h6>Full Name </h6>
                      <b>{{ userData.fullname }}</b>
                    </td>
                    <td>
                      <h6>Annual Income </h6>
                      <b>₹ {{ currency(userData.annualincome) }} </b>
                    </td>
                    <td>
                      <h6>Occupation </h6>
                      <b>{{ userData.employmenttype }} </b>
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-0">
                      <h6>Education </h6>
                      <b>{{ getEducationText(userData.education) }}</b>
                    </td>
                    <td class="pb-0">
                      <h6>PIN Code </h6>
                      <b>{{ userData.zipcode }}</b>
                    </td>
                    <!-- <td class="pb-0">
                      <h6>City </h6>
                      <b>Mumbai </b>
                    </td> -->
                    <td class="pb-0">
                      <h6>Nationality </h6>
                      <b>Indian </b>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="card z-depth-0 bordered">
            <div class="card-header" id="headingOne">
              <div class="d-flex justify-content-between align-items-center">
                <h6>Profile Details</h6>
                <div class="d-flex cursor-pointer" data-toggle="modal" data-target="#editprofile">
                  <img src="~@/assets/images/edit-profile.png">
                  Edit
                </div>
              </div>
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="true" aria-controls="collapseTwo">
                  Collapsible Group Item #1
                </button>
              </h5>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingOne"
              data-parent="#checkoutaccordion">
              <div class="card-body">
                <table>
                  <tr>
                    <td class="pb-0">
                      <h6>Gender </h6>
                      <b>{{ userData.gender }}</b>
                    </td>
                    <td class="pb-0">
                      <h6>Date Of Birth </h6>
                      <b>{{ dateconvert(userData.dob) }} </b>
                    </td>
                    <td class="pb-0">
                      <h6>Tobacco User</h6>
                      <b>{{ userData.smoke }} </b>
                    </td>
                    <td class="pb-0">
                      <h6>Phone Number  </h6>
                      <b>{{ userData.mobile }} </b>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="card z-depth-0 bordered">
            <div class="card-header" id="headingOne">
              <div class="d-flex justify-content-between align-items-center">
                <h6>Plan Info</h6>
                <div class="d-flex cursor-pointer" @click="updatePlanInfo()">
                  <img src="~@/assets/images/edit-profile.png">
                  Edit
                </div>
              </div>
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="true" aria-controls="collapseThree">
                  Collapsible Group Item #1
                </button>
              </h5>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingOne"
              data-parent="#checkoutaccordion">
              <div class="card-body">
                <table>
                  <tr>
                    <td class="pb-0">
                      <h6>Life Cover </h6>
                      <b>₹ {{ currency(selectedPlan['individualDetails'][0]['sumAssured']) }} </b>
                    </td>
                    <td class="pb-0">
                      <h6>Cover For </h6>
                      <b>{{ parseInt(selectedPlan.individualDetails[0].age) + parseInt(selectedPlan.productDetails.policyTerm) }} Years </b>
                    </td>
                    <td class="pb-0">
                      <h6>Pay For</h6>
                      <b>{{ selectedPlan.productDetails.premiumPaymentTerm }} Years </b>
                    </td>
                    <td class="pb-0">
                      <h6>Mode Of Premium Payment </h6>
                      <b>{{ getPPF(selectedPlan.productDetails.premiumPaymentFrequency) }} </b>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="card z-depth-0 bordered">
            <div class="card-header" id="headingOne">
              <div class="d-flex justify-content-between align-items-center">
                <h6>Upgrade Plan</h6>
                <div class="d-flex cursor-pointer" @click="upgradePlanInfo()">
                  <img src="~@/assets/images/edit-profile.png">
                  Edit
                </div>
              </div>
              <!-- <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFour"
                  aria-expanded="true" aria-controls="collapseFour">
                  Collapsible Group Item #1
                </button>
              </h5> -->
            </div>
            <div id="collapseFour" class="collapse" aria-labelledby="headingOne"
              data-parent="#checkoutaccordion">
              <div class="card-body">
                <table>
                  <tr>
                    <!-- <td class="pb-0">
                      <b>Premium: ₹ {{
                          currency(selectedPlan.individualDetails[0].premiumDetails[0].premiumValue)
                        }} / {{
                          getPayMode(selectedPlan.individualDetails[0].premiumDetails[0].mode)
                        }}</b>
                    </td> -->
                    <td class="pb-0">
                      <h6>Benefit Payout To Nominee</h6>
                      <b>{{ getOptionSelectedVal(selectedPlan.productDetails.DBPoption.optionSelected) }}</b>
                    </td>
                    <td class="pb-0">
                      <h6>Pay Premium</h6>
                      <b>{{ getPpoVal(selectedPlan.productDetails.premiumPaymentOption) }}</b>
                    </td>
                    <td class="pb-0" v-if="isWholeLifeSelected == '1'">
                      <h6>Whole Life</h6>
                      <b>Acquired</b>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="card z-depth-0 bordered">
            <div class="card-header" id="headingOne">
              <div class="d-flex justify-content-between align-items-center">
                <h6>Addons/Riders</h6>
                <div class="d-flex cursor-pointer" @click="updateRiderInfo()">
                  <img src="~@/assets/images/edit-profile.png">
                  Edit
                </div>
              </div>
            </div>
            <div id="collapseFive" class="collapse" aria-labelledby="headingOne"
              data-parent="#checkoutaccordion">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3 justify-content-between d-flex flex-column" v-for="(value, ind) in selectedPlan.productDetails.AddOnSection.addOnSelected"
                      :key="ind"
                      v-bind:value="value">
                      <h6> {{ getOptSelectedText(value.optionSelected) }} </h6>
                      <b v-if="value.premiumIncludedFlag == 'No'">
                        Premium: ₹ {{ value.premiumDetails[0].totalPremiumValue }}
                      </b>
                      <b v-else>Free</b>
                  </div>
                  <div class="col-md-3 col-md-3 justify-content-between d-flex flex-column" v-for="(value, ind) in selectedPlan.individualDetails[0].rider"
                      :key="ind"
                      v-bind:value="value">
                      <h6> {{ value.riderName }} </h6>
                      <b>
                        Premium: ₹ {{ value.premiumDetails[0].totalRiderPremiumValue }}
                      </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-total-premium d-flex justify-content-between align-items-center position-absolute">
          <div class="total-premium">
            <b>Total Premium: </b>
            &#8377; <strong>{{
              this.currency(selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue)
            }}</strong>/{{
              getPayMode(selectedPlan.individualDetails[0].premiumDetails[0].mode)
            }}
            <span class="feature-name">
              <img src="~@/assets/images/info-button.png" alt=""/>
              <div class="tooltip-sec">
                <table>
                  <tr>
                    <td>  
                      Premium:
                    </td>
                    <td>₹ 
                      {{
                      this.currency(selectedPlan.individualDetails[0].premiumDetails[0].premiumValue)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">  
                      Taxes:
                    </td>
                    <td>₹  
                      {{this.currency(selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue)}}
                    </td>
                  </tr>
                </table>
              </div>
            </span>
            <span class="premium-tax">Premium (Including Taxes)</span>
          </div>
          <router-link to="/proposal">
            <button type="submit" class="next-btn mt-0 d-flex justify-content-center align-items-center">Checkout <i><img src="~@/assets/images/next-arrow.png"></i></button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="d-lg-none footer-total-premium d-flex justify-content-between align-items-center position-absolute">
      <div class="total-premium">
        <b>Total Premium: </b>
        &#8377; <strong>{{
            selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue
          }}</strong>/{{
            getPayMode(selectedPlan.individualDetails[0].premiumDetails[0].mode)
          }}
          

      </div>
      <router-link to="/proposal">
        
          <button type="submit" class="next-btn mt-0 d-flex justify-content-center align-items-center">Checkout <i><img src="~@/assets/images/next-arrow.png"></i></button>
        
      </router-link>
    </div>
  </div>

</div>
</template>


<script>
import moment from 'moment';
import {getPolicyCover, getConpareData, getInsuranceImages, getDBPMaster, getPPOMaster, getOPTSelected} from '@/mixins/common'
export default {
  name: 'checkout',
  props: [
    'userProfileData',
    'userProfileData2'
  ],
  mixins:[getPolicyCover, getConpareData, getInsuranceImages, getDBPMaster, getPPOMaster, getOPTSelected],
  watch: { 
    userProfileData: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.userData = newVal;
    },
    userProfileData2: function(newVal, oldVal) { // watch it
      console.log('Prop changed2: ', newVal, ' | was2: ', oldVal)
      this.user2Data = newVal;
    }
  },
  components: {
 
  },
  
  mounted() {

    sessionStorage.setItem("quote_request_checkout_flag", JSON.stringify(1));

    if (localStorage.getItem("userProfile")){
      this.userData = JSON.parse(localStorage.getItem("userProfile"))
    }
    this.selectedData = JSON.parse(sessionStorage.getItem("selectedPlanForCheckout"));
    this.uiQuoteId = JSON.parse(sessionStorage.getItem("uiQuoteId"));
    this.quoteRequestID = JSON.parse(sessionStorage.getItem("uiQuoteId"));
    
    this.isWholeLifeSelected = JSON.parse(sessionStorage.getItem("isWholeLifeSelected"));

    //this.selectedData = JSON.parse(localStorage.getItem("selectedPlanForCheckout"));
    console.log("selected data5: ", this.selectedData);
    this.selectedPlan = this.selectedData;
    
     let logData = {
        "ui_quote_id": this.uiQuoteId,
        "quote_request_id": this.quoteRequestID,
        "mobile_number": this.userData.mobile,
        "dob": this.userData.dob,
        "page_name": "checkout",
        "tag_name":"",
        "data": JSON.stringify(this.selectedData)
      };
      this.saveLogData(logData);

      this.includeAddOnsPremium();
      this.includeRidersPremium();

      localStorage.setItem('currentRoute', JSON.stringify("checkout"));
  },
  data() {
    return {
      //editProfileClicked: 0,
      userData: {},
      selectedData: "",
      selectedPlan: "",
      uiQuoteId: "",
      quoteRequestID: "",
      isWholeLifeSelected: 0,
    }
  },
  
  methods: {
    editPersonalInfo() {
      this.editProfileClicked = 1;
      console.log('edit personal info called')
      localStorage.setItem('editProfileClicked', JSON.stringify(this.editProfileClicked));
      this.$router.push('/');
    },
    saveLogData(logData) {
      console.log('save log data called');
      var  headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_DATA_PER_USERNPASS),
        "Content-Type": "application/json",
      };
      //console.log('logData: ', logData);
      this.backendApi("saveLogData", logData, headers)
        .then((response) => {
          //console.log('save quote response: ', response);
           if(response == 'error') {
            //self.apiError = true;
          } else if(response && response.data){
            console.log('log data saved on checkout page');
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
        });
    },
    getEducationText(val) {
      let text;
      if(val == '10') {
        text = '10th Pass & below';
      } else if(val == '12') {
        text = '12th Pass';
      } else {
        text = 'College graduate & above';
      }
      return text;
    },
    getInsurerImgs(value) {
      let img = getInsuranceImages();
      return require("../../assets/images/" + img[value]);
    },
    getPPF(val) {
      if (val == "M") {
        return "Monthly";
      } else if (val == "Q") {
        return "Quarterly";
      } else if (val == "HY") {
        return "Half Yearly";
      } else if (val == "Y") {
        return "Yearly";
      }
    },
    getPayMode(val) {
      if (val == "M") {
        return "Month";
      } else if (val == "Q") {
        return "Quarter";
      } else if (val == "HY") {
        return "Half Year";
      } else if (val == "Y") {
        return "Year";
      }
    },
    updatePlanInfo() {
      console.log('update plan info called')
      this.$router.push('/policydetails');
    }, 
    upgradePlanInfo() {
      console.log('upgrade plan info called')
      localStorage.setItem('updatedePlanInfoClicked', JSON.stringify(2));
      this.$router.push('/policydetails');
    },
    updateRiderInfo() {
      console.log('update rider info called')
      localStorage.setItem('updatedePlanInfoClicked', JSON.stringify(3));
      this.$router.push('/policydetails');
    },
    currency(amt) {
      return new Intl.NumberFormat().format(amt)
    },
    includeAddOnsPremium() {
      let addOnsAmt = 0;
      let addOnsAmtTotal = 0;
      let addOnsTax = 0;
      this.selectedPlan.productDetails.AddOnSection.addOnSelected.forEach(
        (element, index) => {
          if( element.premiumIncludedFlag == 'No') {
            addOnsAmt += element.premiumDetails[0].premiumValue;
            addOnsTax += element.premiumDetails[0].tax[0].taxValue;
            addOnsAmtTotal += element.premiumDetails[0].totalPremiumValue;
          }
        }
      );
      this.selectedPlan.individualDetails[0].premiumDetails[0].premiumValue += addOnsAmt;
      this.selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue += addOnsTax;
      this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue += addOnsAmtTotal;
    },
    includeRidersPremium() {
      let riderAmt = 0;
      let riderAmtTotal = 0;
      let riderTax = 0;
      this.selectedPlan.individualDetails[0].rider.forEach(
        (element, index) => {
          riderAmt += element.premiumDetails[0].riderPremiumValue;
          riderTax += element.premiumDetails[0].tax[0].taxValue;
          riderAmtTotal += element.premiumDetails[0].totalRiderPremiumValue;
        }
      );
      this.selectedPlan.individualDetails[0].premiumDetails[0].premiumValue += riderAmt;
      this.selectedPlan.individualDetails[0].premiumDetails[0].tax[0].taxValue += riderTax;
      this.selectedPlan.individualDetails[0].premiumDetails[0].totalPremiumValue += riderAmtTotal;
    },
    dateconvert(){
      const formattedDate = moment(this.userData.dob).format('DD/MM/YYYY')
     // console.log(formattedDate)
      return formattedDate;
    },
    titleCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          // You do not need to check if i is larger than splitStr length, as your for does that for you
          // Assign it back to the array
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      // Directly return the joined string
      return splitStr.join(' '); 
    },
    getOptionSelectedVal(val) {
      console.log('getOptionSelectedVal called: ', val);
      let data = getDBPMaster();
      return data[val];
    },
    getPpoVal(val) {
      let data = getPPOMaster();
      return data[val];
    },
    getOptSelectedText(value) {
      return getOPTSelected()[value];
    },

    
  },
  

}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/checkout.scss';
</style>
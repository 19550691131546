<template>
  <div class="inner-header d-flex justify-content-between align-items-center">
    <div class="brand-logo">
      <a href="https://www.BajajCapital.com"><img src="~@/assets/images/logo.png" alt="" /></a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "innerheader",
  emits: ["clicked-rm-details"],
  props: {
    msg: String,
  },
  components: {
    
  },
  mounted() {
    console.log(new URL(location).origin+"/#/proposaldetailscheckdiy")
    // let rmId = this.$route.query.id;
    // console.log(this.$route)
    // console.log('rm id:', rmId)
    // let qData = [rmId];
    // let headers = {};
    // if(rmId) {
    //   this.backendApi("getRmDetails", qData, headers, "get")
    //   .then((response) => {
    //     //console.log('save quote response: ', response);
    //     if(response == 'error') {
    //       //self.apiError = true;
    //       alert('RM data not fetched, please refresh the page');
    //     } else if(response && response.data) {
    //       this.rmData = response.data[0];
    //       console.log('rmData: 4: ', this.rmData);
    //       sessionStorage.setItem("rm_data", JSON.stringify(this.rmData));
    //       localStorage.setItem("rm_data", JSON.stringify(this.rmData));
    //       this.$emit('clicked-rm-details', this.rmData);
    //       this.getToken();
    //     }
    //   })
    //   .catch((error) => {
    //     console.log('error msg block dp', error);
    //   });

    // }
    
    /* if(localStorage.getItem('rm_data')) {
      this.rmData = JSON.parse(localStorage.getItem("rm_data"));
      console.log('RM DATA: header: ', this.rmData);
    } */

    // if (sessionStorage.getItem("rm_data")){
    //   this.rmData = JSON.parse(sessionStorage.getItem("rm_data"))
    // }
    
  },
  data() {
    return {
      rmData: "",
    };
  },
  methods: {
    getToken() {
      this.backendApi("getToken")
      .then((response) => {
        //console.log('save quote response: ', response);
          if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data){
          //console.log('response token: ', response.data)

          let token = response.data.Data.Token;
          let USERID = response.data.Data.UserID;
          let key = this.rmData.Userid;

          this.getSPDetails(token, USERID, key);
        }

        
      })
      .catch((error) => {
        console.log('error msg block get token', error);
      });
    },
    getSPDetails(token, USERID, key) {
      let qData = {
        "data":{
          "UserID": USERID, 
          "Token": token,  
          "Key": key 
        }
      }
      this.backendApi("getSPDetails", qData)
      .then((response) => {
          if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data) {
          if(response.data.Data[0] == undefined)
          {
            let tmpData = {
              "advisorCode": "149306",
              "channelType": "BR",
              "cusBankAccNo": "",
              "bankName": "BBBJ",
              "needRiskProfile": "",
              "csrLimCode": "99999 (Dummy code)",
              "cafosCode": "99999",
              "oppId": "",
              "fscCode": "01325137",
              "spCode": "109337",
              "bankBrnch": "SBIN",
              "source": "0BBJ",
              "lanNo": "",
              "selectedTab": "",
              "subChannel": ""
            };            
            sessionStorage.setItem("sp_details_data", JSON.stringify(tmpData));
            localStorage.setItem("sp_details_data", JSON.stringify(tmpData));
          }
          else
          {
            console.log("defined SP");
            sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.Data[0]));
            localStorage.setItem("sp_details_data", JSON.stringify(response.data.Data[0]));
          }          
        }
      })
      .catch((error) => {
        console.log('error msg block sp', error);
      });
    },
    
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/inner-header.scss";
</style>
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Policyquote from '../views/policy-quote.vue'
import compareplan from '../views/compare-plan.vue'
import policydetails from '../views/policy-details.vue'
import checkout from '../views/checkout.vue'
import proposal from '../views/proposal.vue'
import proposaldetailscheck from '../views/proposaldetailscheck.vue'
import proposaldetailscheckdiy from '../views/proposaldetailscheckdiy.vue'
import thankYouReject from'../views/thankyourejectdiy.vue'
import thankYouhdfc from'../views/thankyoupageRM.vue'
import dashboard from'../views/dashboard.vue'
import payment from'../views/payment.vue'
import uploaddocument from '../views/upload-doc.vue'
import termplanqte from '../views/termplanqte.vue'
import thankYoumail from'../views/thankyoumail.vue'
import alertpage from '../views/alertpage.vue'
import alertdiy from '../views/alert-diy.vue'
import personalLoanThankYou from '../components/personal-loan-thankyou.vue'
import personalLoanLogin from '../components/personal-loan-login.vue'
import personalLoan from '../components/personal-loan.vue'
import loanDetails from '../components/loan-details.vue'
import attributePage from '../views/attributePage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'dashbord',
    component: dashboard
  },
  {
    path: '/attributePage',
    name: 'attributePage',
    component: attributePage
  },
  {
    path: '/policy-quote',
    name: 'policyquote',
    component: Policyquote
  },
  {
    path: '/compareplan',
    name: 'compareplan',
    component: compareplan
  },
  {
    path: '/personal-loan',
    name: 'personal-loan',
    component: personalLoan
  },
  {
    path: '/loan-details',
    name: 'loan-details',
    component: loanDetails
  },
  {
    path: '/personal-loan-login',
    name: 'personal-loan-login',
    component: personalLoanLogin
  },
  {
    path: '/personal-loan-thankyou',
    name: 'personal-loan-thankyou',
    component: personalLoanThankYou
  },
  {
    path: '/policydetails',
    name: 'policydetails',
    component: policydetails
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: checkout
  },
  {
    path: '/proposal',
    name: 'proposal',
    component: proposal
  },
  {
    path: '/payment',
    name: 'paymnt',
    component: payment
  },
  {
    path: '/proposaldetailscheck',
    name: 'proposaldetailscheck',
    component: proposaldetailscheck
  },
  {
    path: '/thankYou',
    name: 'thankYouReject',
    component: thankYouReject
  },
  
  {
    path: '/thankyoupage',
    name: 'thankYouPage',
    component: thankYouhdfc
  },
  {
    path: '/thankyoumail',
    name: 'thankYouMail',
    component: thankYoumail
  },
  {
    path: '/alert',
    name: 'alertpage',
    component: alertpage
  },
  {
    path: '/alert-diy',
    name: 'alertdiy',
    component: alertdiy
  },
  {
    path: '/proposaldetailscheckdiy',
    name: 'proposaldetailscheckdiy',
    component: proposaldetailscheckdiy
  },
  {
    path: '/upload-doc',
    name: 'uploaddoc',
    component: uploaddocument
  },
  {
    path: '/termplanqte',
    name: 'term-plan-qte',
    component: termplanqte
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) { 
    // always scroll to top
    return { top: 0 }
  }
})
export default router

// const router = createRouter({
//   history: createWebHistory(),
//   routes
// })
// export default router

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
// export default router

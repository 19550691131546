<template>
<div>
  <div class="loading_block_outer" v-if="loading">
    <Loading />
  </div>
  <div v-else class="inner-page-bg">
     <innerheader/>
    <div class="checkout-container">
      <div class="policyname d-flex justify-content-between align-items-center">
        <div class="policyname-text d-flex align-items-center">
          <span class="mr-4">
            <!-- <img src="~@/assets/images/HDFC_Life.png"> -->
            <!-- <img
              :src="
                getInsurerImgs(
                  selectedPlan['insuranceAndProducts']['insuranceCompanyCode']
                )
              "
              alt=""
            /> -->
          </span>
          <!-- <h6>gfshjf</h6> -->
        </div>
        <!-- <div>
          <a href="#" target="_blank">
            <button class="bi-btn next-btn"> Business Illustration </button>
          </a>
        </div> -->
      </div>
      <div class="chckout-table rounded position-relative">
        <h5>Congratulation! Loan of Amount ₹{{userData.loanAmmount}} has been disbursed</h5>
        <!-- <span class="subtext d-block text-center">These Cannot Be Changed At A Later Stage</span> -->
        <div class="accordion" id="checkoutaccordion">
          <div class="card z-depth-0 bordered">
            <div class="card-header" id="headingOne">
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="loan">Loan Details</h4>
                <!-- <div class="d-flex cursor-pointer" data-toggle="modal" data-target="#editprofile">
                  <img src="~@/assets/images/edit-profile.png">
                  Edit
                </div> -->
              </div>
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  Collapsible Group Item #1
                </button>
              </h5>
            </div>
            <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
              data-parent="#checkoutaccordion">
              <div class="card-body">
                <table>
                  <tr>
                    <td>
                      <h6>Account Number</h6>
                      <b>{{accountNumber}}</b>
                    </td>
                    <td>
                      <h6>Loan Amount</h6>
                      <b>₹ {{userData.loanAmmount}}</b>
                    </td>
                    <td>
                      <h6>Intrest Rate</h6>
                      <b>{{intrest}} %</b>
                    </td>
                  </tr>
                  
                </table>
              </div>
            </div>
          </div>
        <h6><b class="heading">Insurance to protect your loan</b></h6>
          <!-- life insurance -->
          <div v-for="(item,ind) in loanDetails" :key="ind">
          <div v-if="item['selected']" class="card z-depth-0 bordered">
            <div class="card-header" id="headingOne">
              <div class="d-flex justify-content-between align-items-center">
                <h6>{{item["insuranceName"]}}</h6>
              </div>
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#collapse'+ind"
                  aria-expanded="true" :aria-controls="'collapse'+ind">
                  Collapsible Group Item #1
                </button>
              </h5>
            </div>
            <div :id="'collapse'+ind" class="collapse" aria-labelledby="headingOne"
              data-parent="#checkoutaccordion">
              <div class="card-body">
                <table>
                  <tr>
                    <td v-if="ind==0" class="pb-5">
                      
                      <!-- <b>{{item["companyName"]}}</b> -->
                      <img class="logo"  src="~@/assets/images/HDFC_Life.png" alt="" />
                    </td>
                    <td v-if="ind!=0" class="pb-5">
                      
                      <img class="logo"  src="~@/assets/images/HDFC_health.png" alt="" />
                    </td>
                    <td v-if="ind==0" class="pb-0">
                      <h6>Covered Amount</h6>
                      <b>₹ {{userData.loanAmmount}}</b>
                    </td>
                    <td v-if="ind!=0" class="pb-0">
                      <h6>Sum Insured</h6>
                      <b>₹ {{userData.loanAmmount}}</b>
                    </td>
                    <td v-if="ind==0" class="pb-0">
                      <h6>Premium Charged</h6>
                      <b>₹ {{userData.singlePremium}}</b>
                    </td>
                    <td  v-if="ind==1" class="pb-0">
                      <h6>Premium Charged</h6>
                      <b>₹ {{userData.premiumHealth}}</b>
                    </td>
                    <td v-if="ind==2" class="pb-0">
                      <h6>Premium Charged</h6>
                      <b>₹ {{userData.premiumAccidential}}</b>
                    </td>
                    <td v-if="ind==0" class="pb-0">
                      <h6>Life Insured</h6>
                      <b>{{userData.fullname}}</b>
                    </td>
                    <td v-if="ind!=0" class="pb-0">
                      <h6>Insured Person</h6>
                      <b>{{userData.fullname}}</b>
                    </td>
                    <td class="pb-0">
                      <h6>Application Number</h6>
                      <b>{{item["appNumber"]}}</b>
                    </td>
                  </tr>
                  <!-- <tr>
                    
                    <td class="pb-0">
                      <h6>Application Number</h6>
                      <b>{{item["appNumber"]}}</b>
                    </td>
                    
                  </tr> -->
                </table>
              </div>
            </div>
          </div>
          </div>

        </div>
        <div class="footer-total-premium d-flex justify-content-center align-items-center position-absolute">
           <div class="text-center">
                <button type="button" class="back-btn" v-on:click="callPersonalLoan">
                  <i><img src="~@/assets/images/back-btn.png" /></i>Back
                </button>
               
                <button type="button" v-on:click="sendMail()" class="next-btn">
                  Submit <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button>

              </div>
         
        </div>
      </div>
    </div> 
    <div class="d-lg-none footer-total-premium d-flex justify-content-between align-items-center position-absolute">
  
      <div class="footer-total-premium d-flex justify-content-center align-items-center position-absolute">
           <div class="text-center">
                <button type="button" class="back-btn" v-on:click="callPersonalLoan">
                  <i><img src="~@/assets/images/back-btn.png" /></i>Back
                </button>
               
                <button type="button" v-on:click="sendMail()" class="next-btn">
                  Submit <i><img src="~@/assets/images/next-arrow.png" /></i>
                </button>

              </div>
         
        </div>
    </div>
   
  </div>

</div>
</template>


<script>
import moment from 'moment';
import innerheader from "@/components/innerheader/innerheader.vue";
import {getInsurerList,getPolicyCover,getInsuranceImages} from '@/mixins/common';
import Loading from "@/components/loading.vue";

export default {
  name: 'loanDetails',
  mixins:[getInsurerList,getPolicyCover,getInsuranceImages],

  
  components: {
    innerheader,
    Loading
  },
  
  mounted() {
    if(!sessionStorage.getItem("current_user"))
   {
    this.$router.push('/personal-loan-login')
   }
   
    let img = getInsuranceImages();
    console.log("img",img)
    this.hdfcLifeImg=img["HDFC"]
    if(JSON.parse(sessionStorage.getItem("UserData")))
    {
      this.userData=JSON.parse(sessionStorage.getItem("UserData"))
      for(let i=0;i<this.loanDetails.length;i++)
      {
        if(i==0)
        this.loanDetails[i]["selected"]=this.userData.checkbox1
        else if(i==1)
        this.loanDetails[i]["selected"]=this.userData.checkbox2
        else if(i==2)
        this.loanDetails[i]["selected"]=this.userData.checkbox3
      }
      
    }

    
  },
  data() {
    return {
      //editProfileClicked: 0,
      loading:false,
      userData: {},
      selectedData: "",
      selectedPlan: "",
      uiQuoteId: "",
      quoteRequestID: "",
      isWholeLifeSelected: 0,
      name:"Poshan Kumar",
      loanAmount: 700000,
      landerName: "Sandesh",
      intrest:10,
      accountNumber:"34567876362",
      personalAdon:[],
      hdfcLifeImg:null,
      loanDetails:[
        {
        id:0,
        selected:false,
        insuranceName: "Life Insurance",
        companyName: "HDFC Life",
        coveredAmount:"1000000",
        premiumCharged:"15600",
        lifeInsured:"Borrower",
        nominee:"Lender",
        appNumber:"4632828289",
        reciept:"download",
        businessIll:"download",
      },
        {
        id:1,
        selected:false,
        insuranceName: "Health Insurance",
        companyName: "HDFC Ergo",
        sumInsured:"1000000",
        premiumCharged:"15600",
        insuredPerson:"Borrower",
        appNumber:"4632828289",
        reciept:"download",
        businessIll:"download",
      },
      {
        id:2,
        selected:false,
        insuranceName: "Personal Accident Insurance",
        companyName: "HDFC Ergo",
        sumInsured:"1000000",
        premiumCharged:"15600",
        insuredPerson:"Borrower",
        appNumber:"4632828289",
        reciept:"download",
        businessIll:"download",
      }]
    }
  },
  
  methods: {
    callLoanThankYou(){
        this.$router.push("/personal-loan-thankyou")
    },
    callPersonalLoan()
    {
     this.$router.push("/personal-loan")
    },
    currency(amt) {
      return new Intl.NumberFormat().format(amt)
    },

    sendMail()
     {
      this.loading=true
      var emailBody = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:th="http://www.thymeleaf.org">
   <head>
      <meta charset="ISO-8859-1" />
      <title>Insert title here</title>
   </head>
   <body>
      <table width="700"border="0"  align="center"  bgcolor="#EEF3FB">
         <tr>
            <td
               style="  font-family: Public Sans; font-weight: 600; font-size: 18px;  color: #1f1f1f; padding: 20px 0px; text-align: center;" >
               <img style="max-width: 150px"src="https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/hdfc-bank.jpg" >
            </td>
         </tr>
         <tr>
            <td>
               <table width="700" border="0"  align="center"   bgcolor="#fff" style="border-radius: 5px; background-color: #fff;
                  margin-left: 30px; margin-right: 30px;" >
                  <tr>
                    
                    <td width="20"></td>
                     <td
                     style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;">
                        Dear ${this.userData.fullname}
                     </td>  
                     <td width="50"></td>
                    
                  </tr>
                  <tr>
                     <td width="20"></td>
                     <td
                        style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;">
                        Thank you for choosing HDFC personal loan.<br>
						Your Loan has been disbursed Worth amount ₹ ${this.userData.loanAmmount}.<br>
            
                       
                     </td>
                     <td width="50"></td>
                  </tr>
                  <tr>
                     <td width="50"></td>
                     <td style=" border-bottom: 1px solid #f0f0f0; font-size: 14px; color: #1f1f1f; padding: 10px 0px 0px 0px; " ></td>
                     <td width="50"></td>
                  </tr>
                
                 
                 
                  <tr>
                     <td width="50"></td>
                     <td
                        style="
                        font-family: Public Sans; font-size: 16px;  color: #1f1f1f; padding: 20px 0px; line-height: 22px;" >
                        For any assistance, please feel free to contact us at 1800 212 123123 or <br>
						email info@hdfcinsurance.com
                     </td>
                     <td width="50"></td>
                  </tr>
				  <tr>
                     <td width="50"></td>
                     <td
                        style="font-family: Public Sans;font-size: 18px;color: #1f1f1f;padding: 20px 0px;line-height: 28px;" >
                        Regards,<br>
						            HDFC Bank
                     </td>
                     <td width="50"></td>
                  </tr>
				  <tr>
                     <td width="50"></td>
                     <td
                        style="font-family: Public Sans; font-size: 10px;  color: #1f1f1f; padding: 20px 0px; line-height: 22px;" >
                        *Insurance is the subject matter of solicitation. IRDAI Insurance Broking License No. 1. License Code No. IRDA/DB 1/22 Valid till 01/01/2023
                     </td>
                     <td width="50"></td>
                  </tr>
               </table>
            </td>
         </tr>
         <tr>
            <td
               style="  font-family: Public Sans; font-size: 12px;  text-align: center; opacity: 0.5; color: #1f1f1f;  padding: 10px; " >
               <center><b style="color: black;"> Powered by </b><strong style="color: blue;font-size: 12px;">9Platforms Technology Pvt Ltd.</strong></center>
            </td>
         </tr>
      </table>
   </body>
</html>`;
   
   let qData = {
          "send_from":"customer.service@1silverbullet.tech",
          "subject":"Your application for personal loan",
          "text": emailBody,
          "send_to":[this.userData.email]
        };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        let getParam=[]
        this.backendApi("sendMail", qData, getParam, headers)
          .then((response) => {
            console.log('email resp: ', response);
            this.loading = false;
            if(response == 'error') {
              self.apiError = true;

              // alert("Error occurred while sending Mail. Please try again!");
            }
            else if(response.status.error=="error")
            {
              
            }
             else if(response && response.data){
              console.log("email sent")
              this.loading=false
              this.$router.push("/personal-loan-thankyou")
              //window.location.href = new URL(location).origin
              
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
          let msg=['We are facing some issue from backend while sending an e-mail']

          });
     }
  }
  

}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/checkout.scss';
.logo{
    width:88px;
    height: 60px;
}

</style>
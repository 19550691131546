<template>
    <div>
        <div class="inner-header-bg">
            <innerheader />
        </div>
        <attribute />
    </div>
</template>

<script>
// @ is an alias to /src
import innerheader from "@/components/innerheader/innerheader.vue";
import attribute from "@/components/attribute/attributeHomePage.vue";

export default {
  name: 'attributePage',
  components: {
     innerheader,
     attribute
  }
}
</script>

// <style scoped lang="scss">
// @import '~@/assets/scss/components/dashboard.scss';
// </style>
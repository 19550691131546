<template>
  <div>
    <div class="container">
        <div class="success-img-holder text-center mt-5 mb-3">
          <figure>
            <img src="~@/assets/images/payment_scuccessful.png" alt="" />
          </figure>
          <figcaption class="mb-4">
            <!-- <h1>Payment Successful!</h1> -->
            <h1>Thank You</h1><br>
            
            
            <span>Your Loan has been disbursed and insurance policies have been issued !</span><br><br>
            <!-- <span>Customer has to review and submit the proposal along with making a payment.</span> -->
            
          </figcaption>
        </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "personal-loan-thankyou",
  props: {  },
  components: {},
  data() {
    return {
    };
  },
  mounted() {
    if(!sessionStorage.getItem("current_user"))
   {
    this.$router.push('/personal-loan-login')
   }
  },
  methods: {
     
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/components/paymentsuccess.scss";
// @import "~@/assets/scss/components/paymentfailed.scss";
</style>